import { ShipmentModel } from "./ShipmentModel";

export interface PickupModel {
  created_at: string;
  id: number;
  name: string;
  planned_for: string;
  shipments: ShipmentModel[];
  status: PickupStatusModel;
  updated_at: string;
}

export enum PickupStatusModel {
  draft = "draft",
  canceled = "canceled",
  confirmed = "confirmed",
  picked = "picked",
}
