import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
} from "@mui/material";
import _ from "lodash";
import { FC, useState } from "react";
import { CustomerModel } from "../../application/models/shared/customerModel";
import { useFetchClientsQuery } from "../../application/api/hooks/client.hooks";
import If from "../../components/conditionalViwer";

interface CustomerSearchFormProps {
  selectCustomer: (user: CustomerModel) => void;
}

export const CustomerSearchForm: FC<CustomerSearchFormProps> = ({
  selectCustomer,
}) => {
  const [input, setInput] = useState<string>("");

  const usersQuery = useFetchClientsQuery(input);
  const users = _.get(
    usersQuery,
    ["data", "data", "data"],
    []
  ) as CustomerModel[];

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <TextField
          size="small"
          fullWidth
          onChange={(e) => setInput(e.target.value)}
          placeholder={"Rechercher"}
        />
      </Grid>
      <Grid item>
        <If
          condition={usersQuery.isLoading}
          render={() => <div>...Loading</div>}
          otherwise={() => (
            <List sx={{ width: "100%", bgcolor: "background.paper" }} dense>
              {users.map((user, index) => (
                <ListItem disablePadding key={index} dense>
                  <ListItemButton dense onClick={() => selectCustomer(user)}>
                    <ListItemAvatar>
                      <Avatar>
                        <PersonOutlineIcon fontSize="small" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${user.first_name} ${user.last_name}`}
                      secondary={`${user.phone_number}`}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
        />
      </Grid>
    </Grid>
  );
};
