import AddIcon from "@mui/icons-material/Add";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import FilterListIcon from "@mui/icons-material/FilterList";
import SendToMobileOutlinedIcon from "@mui/icons-material/SendToMobileOutlined";
import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { green } from "@mui/material/colors";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import { get } from "lodash";
import * as React from "react";
import { Link } from "react-router-dom";
import { useFetchShipmentsQuery } from "../../application/api/hooks/shipment.hooks";
import { FetchShipmentsResponseModel } from "../../application/api/services/shipment.service";
import {
  CREATE_PACKAGE_ROUTE,
  DETAILS_PACKAGE_ROUTE,
} from "../../application/constants/routeConstants";
import { priceFormat } from "../../application/helpers/priceFormat";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { ShipmentStatusEnumModel } from "../../application/models/shared/shipmentStatusModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { PageTitle } from "../../components/PageTitle";
import { SelectShipmentStatus } from "../../components/SelectShipmentStatus";
import { ShipmentStatusChip } from "../../components/shipmentStatusChip";
import { SnackbarContext } from "../../components/snackbarProvider";
import { StyledTableCell } from "../../components/Styled/StyledTableCell";
import { StyledTableRow } from "../../components/Styled/StyledTableRow";

interface StateModel {
  currentPage: number;
  searchText: string;
  status: ShipmentStatusEnumModel[];
  selected: string[];
  sendSmsProgress: boolean;
}

const ListShipmentsPage: React.FC<PagePropsModel> = (): JSX.Element => {
  const checkboxTable = false;
  const [snackbar, setSnackbar] = React.useContext(SnackbarContext);
  const [state, setState] = React.useState<StateModel>({
    currentPage: 1,
    searchText: "",
    status: [],
    selected: [],
    sendSmsProgress: false,
  });

  const resendSms = () => {
    if (state.selected.length > 0) {
      console.log("");
    }
  };

  const shipmentsQuery = useFetchShipmentsQuery({
    pagination: { page: state.currentPage, per_page: 10 },
    status: state.status,
  });
  const {
    data: { data, current_page, total },
  } = get(shipmentsQuery, ["data"], {
    data: [],
    current_page: 1,
    total: 0,
  }) as FetchShipmentsResponseModel;

  const [open, setOpen] = React.useState(false);
  const toggleFilter = () => {
    setOpen((prev) => !prev);
  };

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Liste des colis envoyés",
    },
  ];

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setState((state) => ({ ...state, currentPage: value, selected: [] }));
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data.map((item) => item.identifier);
      setState((state) => ({ ...state, selected: newSelecteds }));
      return;
    }
    setState((state) => ({ ...state, selected: [] }));
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = state.selected.indexOf(id);

    event.stopPropagation();

    setState((state) => ({
      ...state,
      selected:
        selectedIndex > -1
          ? state.selected.filter((item) => item !== id)
          : [...state.selected, id],
    }));
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title="List des Colis" />
        <Grid container justifyContent="space-between">
          <Grid item>
            {" "}
            <AppBreadcrumbs breadcrumbs={breadcrumbs} />
          </Grid>
          <Grid item>
            <Button
              component={Link}
              variant="contained"
              // size="small"
              to={CREATE_PACKAGE_ROUTE}
              color="primary"
              // sx={{ color: "black" }}
              startIcon={<AddIcon />}
            >
              Nouveau colis
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {shipmentsQuery.isError === true && shipmentsQuery.isLoading === false && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="error">Error</Alert>
          </Box>
        )}
        {shipmentsQuery.isLoading === false && data?.length === 0 && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="info">Aucune donnée disponible!</Alert>
          </Box>
        )}
        {shipmentsQuery.isLoading && <LinearProgress />}

        <Paper sx={{ overflow: "hidden" }}>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(state.selected.length > 0 && {
                bgcolor: (theme) =>
                  alpha(
                    theme.palette.primary.main,
                    theme.palette.action.activatedOpacity
                  ),
              }),
            }}
          >
            {state.selected.length > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%", fontWeight: 500 }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {state.selected.length} Sélectionnés
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%", fontWeight: 500 }}
                id="tableTitle"
                component="div"
              >
                {total} Colis
              </Typography>
            )}
            {state.selected.length > 0 ? (
              <Tooltip title="Envoyer un sms">
                <IconButton
                  onClick={() => resendSms()}
                  disabled={state.sendSmsProgress}
                >
                  {state.sendSmsProgress ? (
                    <CircularProgress
                      size={30}
                      sx={{
                        color: green[500],
                      }}
                    />
                  ) : (
                    <SendToMobileOutlinedIcon />
                  )}
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Filter list">
                <IconButton onClick={toggleFilter}>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            )}
          </Toolbar>

          <Collapse in={open}>
            <Box sx={{ p: 2 }}>
              <SelectShipmentStatus
                value={state.status}
                onChange={(value) =>
                  setState((state) => ({ ...state, status: value }))
                }
              />
            </Box>
          </Collapse>

          {total > 0 && data?.length > 0 && (
            <TableContainer>
              <Table
                sx={{ width: "100%" }}
                //  size="small"
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    {checkboxTable && (
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          indeterminate={
                            state.selected.length > 0 &&
                            state.selected.length < data?.length
                          }
                          checked={
                            data?.length > 0 &&
                            state.selected.length === data?.length
                          }
                          onChange={handleSelectAllClick}
                          inputProps={{
                            "aria-label": "select all desserts",
                          }}
                        />
                      </StyledTableCell>
                    )}
                    <StyledTableCell align="left">
                      Date d'entrée
                    </StyledTableCell>
                    {/* <StyledTableCell align="left">Expéditeur</StyledTableCell> */}
                    <StyledTableCell align="left">Destinateur</StyledTableCell>
                    <StyledTableCell align="left">Poids</StyledTableCell>
                    <StyledTableCell align="left">CRBT</StyledTableCell>
                    <StyledTableCell align="left">Code</StyledTableCell>
                    <StyledTableCell align="left">Statut</StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(data || []).map((row, index) => {
                    const isItemSelected = state.selected.includes(
                      row.identifier
                    );
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <StyledTableRow
                        key={index}
                        hover
                        onClick={(event) => {
                          if (checkboxTable) {
                            handleClick(event, row.identifier);
                          }
                        }}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                      >
                        {checkboxTable && (
                          <StyledTableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </StyledTableCell>
                        )}
                        <StyledTableCell component="th" scope="row">
                          {row.received_at}
                        </StyledTableCell>
                        {/* <StyledTableCell component="th">
                          {row.sender?.first_name || "--"}{" "}
                          {row.sender?.last_name || "--"}
                        </StyledTableCell> */}
                        <StyledTableCell>
                          {row.recipient?.first_name || "--"}{" "}
                          {row.recipient?.last_name || "--"}
                        </StyledTableCell>
                        <StyledTableCell>{row.weight} Kg</StyledTableCell>
                        <StyledTableCell>
                          {row.reimbursement?.enable
                            ? priceFormat(row.reimbursement.amount)
                            : "--"}
                        </StyledTableCell>
                        <StyledTableCell>{row?.identifier}</StyledTableCell>
                        <StyledTableCell>
                          <ShipmentStatusChip
                            active={true}
                            status={row?.status as ShipmentStatusEnumModel}
                            size="small"
                            variant="outlined"
                          />
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <IconButton
                            component={Link}
                            aria-label="more"
                            id="long-button"
                            color="inherit"
                            to={`${DETAILS_PACKAGE_ROUTE}/${row.identifier}`}
                            // onClick={(event) => {
                            //   event.stopPropagation();
                            // }}
                          >
                            <ReadMoreIcon fontSize="small" />
                          </IconButton>

                          {/* <IconButton
                        aria-label="more"
                        id="long-button"
                        color="error"
                        onClick={() => console.log(row)}
                      >
                        <DeleteOutlineOutlinedIcon fontSize="small" />
                      </IconButton> */}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>{" "}
            </TableContainer>
          )}
        </Paper>

        <div style={{ float: "right", padding: 20 }}>
          {total > 0 && (
            <Pagination
              count={Math.ceil(Number(total) / 10)}
              color="primary"
              showFirstButton
              showLastButton
              page={state.currentPage}
              onChange={handleChangePage}
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default ListShipmentsPage;
