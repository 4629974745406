import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DoDisturbOutlinedIcon from "@mui/icons-material/DoDisturbOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { DETAILS_PACKAGE_ROUTE } from "../../application/constants/routeConstants";
import { priceFormat } from "../../application/helpers/priceFormat";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { PickupStatusModel } from "../../application/models/shared/pickupModel";
import { ShipmentStatusEnumModel } from "../../application/models/shared/shipmentStatusModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { ConfirmDialog } from "../../components/confirmDialog";
import { PageTitle } from "../../components/PageTitle";
import { ShipmentStatusChip } from "../../components/shipmentStatusChip";
import { StyledTableCell } from "../../components/Styled/StyledTableCell";
import { StyledTableRow } from "../../components/Styled/StyledTableRow";
import { PickupActionEnum, usePickupState } from "./state";
import ReadMoreIcon from "@mui/icons-material/ReadMore";

const PickupDetailsPage: FC<PagePropsModel> = (props): JSX.Element => {
  const id = props?.match?.params?.id || undefined;

  const {
    setAction,
    action,
    resetAction,
    providerDialogParams,
    findOne,
    isLoading,
    error,
    details,
  } = usePickupState();

  useEffect(() => {
    if (id) {
      findOne(Number(id));
    }
  }, []);

  // const onePickupQuery = useFindOneQuery(Number(id));
  // const pickupData = get(onePickupQuery, ["data"], undefined) as PickupModel;

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Detail pickup ",
    },
  ];

  const sum = (details?.shipments || []).reduce((acc, curr) => {
    return Number(acc + curr.price);
  }, 0);

  return (
    <>
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <PageTitle title="Detail Pickup" />
          <Grid container justifyContent="space-between">
            <Grid item>
              {" "}
              <AppBreadcrumbs breadcrumbs={breadcrumbs} />
            </Grid>
            <Grid item></Grid>
          </Grid>
        </Grid>
        <Grid item>
          {Boolean(error) && isLoading === false && (
            <Box sx={{ pb: 3 }}>
              <Alert severity="error">Error</Alert>
            </Box>
          )}
          {isLoading === false && details === undefined && (
            <Box sx={{ pb: 3 }}>
              <Alert severity="info">Aucune donnée disponible!</Alert>
            </Box>
          )}
          {isLoading && <LinearProgress />}
          {details !== undefined && (
            <Box>
              <Stack direction={"row"} spacing={2}>
                <Box sx={{ flexGrow: 1 }}>
                  {details.shipments?.length > 0 && (
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ width: "100%" }}
                        //size="small"
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="left">
                              Date d'entrée
                            </StyledTableCell>
                            {/* <StyledTableCell align="left">
                            Expéditeur
                          </StyledTableCell> */}
                            <StyledTableCell align="left">
                              Destinateur
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Poids
                            </StyledTableCell>
                            <StyledTableCell align="left">Code</StyledTableCell>
                            <StyledTableCell align="left">
                              Statut
                            </StyledTableCell>
                            <StyledTableCell align="right"></StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(details.shipments || []).map((row, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <StyledTableRow
                                key={index}
                                hover
                                role="checkbox"
                                tabIndex={-1}
                              >
                                <StyledTableCell component="th" scope="row">
                                  {row.received_at}
                                </StyledTableCell>
                                {/* <StyledTableCell component="th">
                                {row.sender?.first_name || "--"}{" "}
                                {row.sender?.last_name || "--"}
                              </StyledTableCell> */}
                                <StyledTableCell>
                                  {row.recipient?.first_name || "--"}{" "}
                                  {row.recipient?.last_name || "--"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.weight} Kg
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row?.identifier}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <ShipmentStatusChip
                                    active={true}
                                    status={
                                      row?.status as ShipmentStatusEnumModel
                                    }
                                    size="small"
                                    variant="outlined"
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  <IconButton
                                    component={Link}
                                    aria-label="more"
                                    id="long-button"
                                    color="inherit"
                                    to={`${DETAILS_PACKAGE_ROUTE}/${row.identifier}`}
                                  >
                                    <ReadMoreIcon fontSize="small" />
                                  </IconButton>
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })}
                        </TableBody>
                      </Table>{" "}
                    </TableContainer>
                  )}
                </Box>
                <Paper sx={{ p: 2, minWidth: 300 }}>
                  <Stack
                    direction={"column"}
                    spacing={0}
                    justifyContent="flex-end"
                  >
                    <Typography variant="caption" color="textSecondary">
                      <b>Nom :</b> {details.name}
                    </Typography>
                    <Divider flexItem orientation="vertical" />
                    <Typography variant="caption" color="textSecondary">
                      <b>Date :</b> {details.planned_for}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      <b>Status :</b> {details.status}
                    </Typography>

                    <Typography variant="caption" color="textSecondary">
                      <b>Total prix :</b> {priceFormat(sum)}
                    </Typography>

                    <Divider flexItem sx={{ mt: 3, mb: 1 }} />

                    <Stack
                      direction={"row"}
                      spacing={1}
                      justifyContent="flex-end"
                    >
                      {details.status !== PickupStatusModel.confirmed &&
                        details.status !== PickupStatusModel.picked && (
                          <Button
                            fullWidth
                            variant="contained"
                            color="error"
                            onClick={(event) => {
                              event.stopPropagation();
                              setAction({
                                item: details,
                                action: PickupActionEnum.TO_REMOVE,
                              });
                            }}
                            startIcon={
                              <DeleteOutlineOutlinedIcon fontSize="small" />
                            }
                          >
                            Supprimer
                          </Button>
                        )}

                      {details.status === PickupStatusModel.confirmed && (
                        <Button
                          fullWidth
                          variant="contained"
                          color="warning"
                          onClick={(event) => {
                            event.stopPropagation();
                            setAction({
                              item: details,
                              action: PickupActionEnum.TO_CANCEL,
                            });
                          }}
                          startIcon={<DoDisturbOutlinedIcon fontSize="small" />}
                        >
                          Annuler
                        </Button>
                      )}

                      {details.status !== PickupStatusModel.confirmed &&
                        details.status !== PickupStatusModel.picked && (
                          <Button
                            fullWidth
                            variant="contained"
                            color="success"
                            onClick={(event) => {
                              event.stopPropagation();
                              setAction({
                                item: details,
                                action: PickupActionEnum.TO_CONFIRM,
                              });
                            }}
                            startIcon={
                              <CheckBoxOutlinedIcon fontSize="small" />
                            }
                          >
                            Valider
                          </Button>
                        )}
                    </Stack>
                  </Stack>
                </Paper>
              </Stack>
            </Box>
          )}
        </Grid>
      </Grid>

      {action.item && action?.action && (
        <ConfirmDialog
          title={providerDialogParams[action?.action].getTitle(action.item)}
          body={providerDialogParams[action?.action].getBody(action.item)}
          dialogProps={{
            open: Boolean(action.item),
            onClose: () => resetAction(),
          }}
          cancelButton={{
            onClick: () => resetAction(),
          }}
          confirmButton={{
            onClick: () => {
              action?.action
                ? providerDialogParams[action?.action].do()
                : console.log();
            },
          }}
        />
      )}
    </>
  );
};
export default PickupDetailsPage;
