import httpClient from "../../httpClient";
import { PostOfficeModel } from "../../models/shared/postOfficeModel";

interface PaginationResponseModel {
  data: { data: PostOfficeModel[] | []; total: number; current_page: number };
}

const fetch = async (params: {
  search?: string;
  per_page?: number;
  page?: number;
}): Promise<PaginationResponseModel> => {
  const { search = undefined, per_page = undefined, page = undefined } = params;
  return httpClient.get("post_offices", {
    params: {
      ...(() => (page ? { page } : {}))(),
      ...(() => (per_page ? { per_page } : {}))(),
      ...(() => (search ? { search } : {}))(),
    },
  });
};

export const postOfficeService = {
  fetch,
};
