import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Theme,
  Toolbar,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { FC, Fragment, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import If from "../../../../components/conditionalViwer";
import { communMenuItems } from "../../../data/menuItems";
import { MenuModel } from "../../../models/shared/menuModel";
import { useAppSelector } from "../../../reducers/hooks";
import { RootState } from "../../../reducers/store";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: 2,
    paddingBottom: 10,
    // border: "solid 1px #C4C4C4",
    //  backgroundColor: "#F4F6F8",
    borderRadius: 0,
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    position: "fixed",
    maxWidth: 320,
    height: "99vh",
    borderRight: "1px dashed rgba(145, 158, 171, 0.24)",
  },
  active: {
    color: theme.palette.secondary.main,
    // borderRight: "solid 1px #FF6600",
  },
}));

export const NavBar: FC = () => {
  const classes = useStyles();

  const menuFilter = (item: MenuModel) => true;

  return (
    <Paper className={classes.root} elevation={0}>
      <Toolbar>
        <Box sx={{ p: 2 }}>
          <img
            className="img-fluid"
            src={`${process.env.REACT_APP_PUBLIC_URL}assets/images/logo.png`}
            alt="logo"
            style={{ height: 60, width: 200 }}
          />{" "}
          {/* <Divider /> */}
        </Box>
      </Toolbar>

      <Box sx={{ overflow: "auto", minWidth: "100%" }}>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          disablePadding
          dense
          subheader={
            <ListSubheader
              disableSticky={true}
              component="div"
              id="nested-list-subheader"
              sx={{ backgroundColor: "transparent" }}
            >
              Géneral
            </ListSubheader>
          }
          sx={{ width: "100%" }}
        >
          {communMenuItems.filter(menuFilter).map((menuItem, index) => (
            <MenuItem {...menuItem} level={0} key={index} />
          ))}
        </List>
      </Box>
    </Paper>
  );
};

const ListMenuItem: FC<MenuModel> = (props): JSX.Element => {
  const classes = useStyles();
  const paddingLeft = props?.level ? props.level + 1 : 2;
  const Icon = props.icon();
  const Component = props?.component ? props.component() : null;

  return (
    <>
      {Component !== null && (
        <Component>
          <ListItem
            button
            component={NavLink}
            dense={true}
            to={props.href || "/"}
            sx={{ pl: paddingLeft }}
            activeClassName={classes.active}
            exact
          >
            <ListItemIcon sx={{ minWidth: 30 }}>
              <Icon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={`${props.label}`} />
          </ListItem>
        </Component>
      )}

      {Component === null && (
        <ListItem
          button
          component={NavLink}
          dense={true}
          to={props.href || "/"}
          sx={{ pl: paddingLeft, width: "100%" }}
          activeClassName={classes.active}
          exact
        >
          <ListItemIcon sx={{ minWidth: 30 }}>
            <Icon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={`${props.label}`} />
        </ListItem>
      )}
    </>
  );
};

const MenuItem: React.FC<MenuModel> = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const isMatchedPath = (path: string): boolean => {
    return location.pathname === path;
  };
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (
      props &&
      props?.subMenuItems !== undefined &&
      props?.subMenuItems.length > 0
    ) {
      const isExpand =
        props?.subMenuItems?.find((_itemMenu) =>
          isMatchedPath(_itemMenu?.href || "undefined")
        ) !== null;

      setOpen(isExpand);
    } else {
      setOpen(false);
    }
  }, [location.pathname]);

  const handleToggle = () => {
    setOpen(!open);
  };

  const paddingLeft = props?.level ? props.level + 1 : 2;
  const Icon = props.icon();
  const Component = props?.component ? props.component() : null;

  return (
    <If
      condition={
        (props && props?.subMenuItems === undefined) ||
        (props &&
          props?.subMenuItems !== undefined &&
          props.subMenuItems.length === 0)
      }
      render={() => <ListMenuItem {...props} />}
      otherwise={() => {
        return (
          <Fragment>
            <ListItemButton
              // LinkComponent={NavLink}
              sx={{ pl: paddingLeft }}
              dense={true}
              selected={false}
              onClick={handleToggle}
              style={{ width: "100%" }}
            >
              <ListItemIcon sx={{ minWidth: 30 }}>
                <Icon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={`${props.label}`} />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {(props?.subMenuItems || []).map((child, index) => (
                  <MenuItem {...child} level={paddingLeft + 4} key={index} />
                ))}
              </List>
            </Collapse>
          </Fragment>
        );
      }}
    />
  );
};
