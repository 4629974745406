import { PickupFormModel } from "../../../pages_components/pickup/forms/models";
import httpClient from "../../httpClient";
import { HttpResponseModel } from "../../models/shared/httpResponseModel";
import { PaginationModel } from "../../models/shared/paginationModel";
import { PickupModel } from "../../models/shared/pickupModel";
import { ShipmentModel } from "../../models/shared/ShipmentModel";

export interface FetchQueryModel {
  pagination: PaginationModel;
  search?: string;
}

export interface PickupsResponseModel extends HttpResponseModel {
  data: PickupModel[] | [];
  current_page: number;
  total: number;
}

const create = async (data: PickupFormModel): Promise<any> => {
  return httpClient.post("pickups", {
    ...data,
  });
};

const remove = async (id: number): Promise<any> => {
  return httpClient.delete(`pickups/${id}`);
};

const fetch = async (
  params: FetchQueryModel
): Promise<PickupsResponseModel> => {
  const { pagination, search = undefined } = params;
  return httpClient
    .get("pickups", {
      params: {
        ...pagination,
      },
    })
    .then(({ data }) => data);
};

const findOne = async (id: number): Promise<PickupModel> => {
  return httpClient.get(`pickups/${id}`).then(({ data }) => data);
};

const update = async (
  data: PickupFormModel & { id: number }
): Promise<PickupModel> => {
  const { id, ...other } = data;

  return httpClient.patch(`settings/users/${id}`, {
    ...other,
  });
};

const confirm = async (id: number): Promise<any> => {
  return httpClient.patch(`pickups/${id}/confirm`);
};

const cancel = async (id: number): Promise<any> => {
  return httpClient.patch(`pickups/${id}/cancel`);
};

export const pickupService = {
  create,
  fetch,
  remove,
  findOne,
  update,
  confirm,
  cancel,
};
