import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import RoomIcon from "@mui/icons-material/Room";
import {
  Button,
  ButtonProps,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { FC } from "react";
import { priceFormat } from "../../../application/helpers/priceFormat";
import { AddShipmentsFormModel } from "../forms";
import { ParcelModel } from "../forms/ParcelForm/models";
import { ShipmentFormModel } from "../forms/RecipientForm/models";

const useStyles = makeStyles((theme: Theme) => ({
  sectionTitle: {
    fontWeight: 500,
    textAlign: "left",
    position: "relative",
    maxWidth: 100,
    color: theme.palette.grey[500],
    marginBottom: theme.spacing(3),
    "&:after": {
      position: "absolute",
      bottom: -8,
      left: 0,
      width: "100%",
      height: 4,
      content: "' '",
      background: theme.palette.secondary.main,
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "transparent",
    color: "#000",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const Validation: FC<{
  formValue: AddShipmentsFormModel;
  previousButton: ButtonProps;
  isLoading: boolean;
  onValide: () => void;
}> = (props) => {
  const classes = useStyles();

  const reducerPackages = (
    previousValue: number,
    currentValue: ParcelModel
  ): number => {
    return Number(previousValue) + Number(currentValue.price);
  };

  const reducerShipment = (
    previousValue: number,
    currentValue: ShipmentFormModel
  ) => {
    const { packages } = currentValue;
    const totalPerPackages = packages.reduce(reducerPackages, 0);
    return Number(previousValue) + Number(totalPerPackages);
  };

  const totalPrice = props.formValue.shipments.reduce(reducerShipment, 0);

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Typography sx={{ fontWeight: 500 }}>Récap</Typography>
      </Grid>
      <Grid item>
        <Grid container alignItems="flex-start" spacing={4}>
          <Grid item xs={12} md={12}>
            <Paper sx={{ p: 3 }}>
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <Typography sx={{ fontWeight: 500 }}>Expéditeur</Typography>
                </Grid>

                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <PersonIcon fontSize="small" color="disabled" />
                        </Grid>
                        <Grid item>
                          {" "}
                          <Typography variant="body2">{`${props.formValue.sender?.first_name} ${props.formValue.sender?.last_name}`}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <RoomIcon fontSize="small" color="disabled" />
                        </Grid>
                        <Grid item>
                          {" "}
                          <Typography variant="body2">{`${props.formValue.sender?.address.address_line} - ${props.formValue.sender?.address?.post_office?.zip_code}`}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <PhoneIcon fontSize="small" color="disabled" />
                        </Grid>
                        <Grid item>
                          {" "}
                          <Typography variant="body2">{`${props.formValue.sender?.phone_number}`}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {props.formValue.shipments.map((shipment, index) => (
            <Grid key={index} item xs={12} md={12}>
              <Paper sx={{ p: 3 }}>
                <Grid container spacing={0} justifyContent="space-between">
                  <Grid item xs={3}>
                    <Grid container direction="column" spacing={3}>
                      <Grid item>
                        <Typography sx={{ fontWeight: 500 }}>
                          Destinataire
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Grid container direction="column">
                          <Grid item>
                            <Grid container alignItems="center" spacing={1}>
                              <Grid item>
                                <PersonIcon fontSize="small" color="disabled" />
                              </Grid>
                              <Grid item>
                                {" "}
                                <Typography variant="body2">{`${shipment.recipient?.first_name} ${shipment.recipient?.last_name}`}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container alignItems="center" spacing={1}>
                              <Grid item>
                                <RoomIcon fontSize="small" color="disabled" />
                              </Grid>
                              <Grid item>
                                {" "}
                                <Typography variant="body2">{`${shipment.recipient?.address.address_line} - ${shipment.recipient?.address.post_office?.zip_code}`}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container alignItems="center" spacing={1}>
                              <Grid item>
                                <PhoneIcon fontSize="small" color="disabled" />
                              </Grid>
                              <Grid item>
                                {" "}
                                <Typography variant="body2">{`${shipment.recipient?.phone_number}`}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Grid item xs={8}>
                    <Grid container direction="column" spacing={3}>
                      <Grid item>
                        <Typography sx={{ fontWeight: 500 }}>Colis</Typography>
                      </Grid>
                      <Grid item>
                        <TableContainer
                          component={Paper}
                          elevation={0}
                          // sx={{ border: "solid 1px #C4C4C4" }}
                        >
                          <Table
                            sx={{ minWidth: "100%" }}
                            size="small"
                            aria-label="a dense table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Désignation</StyledTableCell>
                                <StyledTableCell>Poids</StyledTableCell>
                                {/* <StyledTableCell align="right">
                                  Embalage
                                </StyledTableCell> */}
                                <StyledTableCell align="right">
                                  CRBT
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  Montant
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {shipment.packages.map((packageItem, indexP) => (
                                <TableRow
                                  key={indexP}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {packageItem?.label || "--"}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {packageItem.weight} Kg
                                  </TableCell>
                                  {/* <TableCell align="right">
                                    {packageItem.packaging.enable
                                      ? packageItem.packaging.type?.type
                                      : "--"}{" "}
                                  </TableCell> */}
                                  <TableCell align="right">
                                    {" "}
                                    {packageItem.reimbursement.enable
                                      ? priceFormat(
                                          packageItem.reimbursement.amount
                                        )
                                      : "--"}{" "}
                                  </TableCell>
                                  <TableCell align="right">
                                    {priceFormat(packageItem.price)}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item>
        <Paper sx={{ p: 3 }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Button
                color="primary"
                type="submit"
                variant="outlined"
                {...props.previousButton}
                startIcon={<KeyboardArrowLeftOutlinedIcon />}
              >
                Retour
              </Button>
            </Grid>
            <Grid item>
              <Stack direction={"row"} spacing={2} alignItems="center">
                <Typography sx={{ fontWeight: 900, fontSize: 20 }}>
                  Total à payer : {priceFormat(totalPrice)}
                </Typography>

                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  onClick={() => props.onValide()}
                  disabled={props.isLoading}
                >
                  Sauvegarder
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
