import { AddShipmentsFormModel } from "../index";
export const addShipmentsIntValuesForm: AddShipmentsFormModel = {
  sender: {
    first_name: "",
    last_name: "",
    phone_number: "",
    address: {
      address_line: "",
      post_office: undefined,
    },
  },
  shipments: [
    {
      recipient: {
        first_name: "",
        last_name: "",
        phone_number: "",
        second_phone_number: "",
        address: {
          address_line: "",
          post_office: undefined,
        },
      },
      packages: [
        {
          label: "",
          weight: 0,
          price: 8,
          packaging: {
            enable: false,
            type: undefined,
          },
          reimbursement: {
            enable: false,
            amount: 0,
            return_mode: "De main à main",
          },
        },
      ],
    },
  ],
};
