import React, { FC } from "react";
import LoginPage from "../../pages/Login";
import { PublicLayout } from "../layouts/PublicLayout";
import { Route, RouteProps, Switch, useRouteMatch } from "react-router-dom";
import { VALIDATION_ACCOUNT_ROUTE } from "../constants/routeConstants";
import ValidationAccountPage from "../../pages/validation_account";

const PublicLayoutRoute: FC<RouteProps> = (props) => {
  const { path } = useRouteMatch();

  return (
    <PublicLayout>
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={(props) => <LoginPage {...props} />}
        />

        <Route
          path={`${VALIDATION_ACCOUNT_ROUTE}/:token?`}
          render={(props) => <ValidationAccountPage {...props} />}
        />
      </Switch>

      {/* <LoginPage /> */}
    </PublicLayout>
  );
};

export default PublicLayoutRoute;
