import { Paper, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3),
    width: "100%",
  },
}));

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export const SimpleBarChart = (): JSX.Element => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Typography style={{ marginBottom: 20, fontWeight: 500 }}>
        Simple BarChart
      </Typography>
      <div style={{ width: "100%", height: 300 }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />

            <XAxis
              strokeWidth={0}
              dataKey="name"
              tick={<CustomizedAxisXTick />}
              tickLine={false}
            />
            <YAxis
              strokeWidth={0}
              tick={<CustomizedAxisYTick />}
              tickLine={false}
            />
            <Tooltip />
            <Legend iconType="circle" iconSize={10} />
            <Bar dataKey="pv" fill="#4E148C" />
            <Bar dataKey="uv" fill="#FF6600" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Paper>
  );
};

const CustomizedAxisXTick = (props: any): JSX.Element => {
  const { x, y, stroke, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        fontSize="smaller"
        textAnchor="end"
        fill="#7A8894"
        transform="rotate(-0)"
      >
        {payload.value}
      </text>
    </g>
  );
};

const CustomizedAxisYTick = (props: any): JSX.Element => {
  const { x, y, stroke, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        // dy={7}
        fontSize="smaller"
        textAnchor="end"
        fill="#7A8894"
        transform="rotate(-0)"
      >
        {payload.value}
      </text>
    </g>
  );
};
