import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DoDisturbOutlinedIcon from "@mui/icons-material/DoDisturbOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";
import SendToMobileOutlinedIcon from "@mui/icons-material/SendToMobileOutlined";
import {
  Alert,
  Checkbox,
  Grid,
  IconButton,
  LinearProgress,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import * as React from "react";
import { Link } from "react-router-dom";
import { DETAILS_PICKUP_ROUTE } from "../../application/constants/routeConstants";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { PickupStatusModel } from "../../application/models/shared/pickupModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { ConfirmDialog } from "../../components/confirmDialog";
import { PageTitle } from "../../components/PageTitle";
import { SnackbarContext } from "../../components/snackbarProvider";
import { StyledTableCell } from "../../components/Styled/StyledTableCell";
import { StyledTableRow } from "../../components/Styled/StyledTableRow";
import { PickupActionEnum, usePickupState } from "./state";
import ReadMoreIcon from "@mui/icons-material/ReadMore";

const ListPickupsPage: React.FC<PagePropsModel> = (): JSX.Element => {
  const [_, setSnackbar] = React.useContext(SnackbarContext);
  const {
    isLoading,
    error,
    data,
    fetchData,
    action,
    resetAction,
    setAction,
    handleChangePage,
    handleSelectAllClick,
    handleClickItem,
    selected,
    status,
    setStatus,
    total,
    remove,
    currentPage,
    providerDialogParams,
    snackbar,
  } = usePickupState();

  React.useEffect(() => {
    fetchData();
  }, [currentPage]);

  React.useEffect(() => {
    if (snackbar.open) {
      setSnackbar(snackbar);
    }
  }, [snackbar.open]);

  const [open, setOpen] = React.useState(false);
  const checkboxTable = false;
  const toggleFilter = () => {
    setOpen((prev) => !prev);
  };

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Liste des colis envoyés",
    },
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title="List des Colis" />
        <Grid container justifyContent="space-between">
          <Grid item>
            {" "}
            <AppBreadcrumbs breadcrumbs={breadcrumbs} />
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Grid>
      <Grid item>
        {Boolean(error) === true && isLoading === false && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="error">Error</Alert>
          </Box>
        )}
        {isLoading === false && data?.length === 0 && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="info">Aucune donnée disponible!</Alert>
          </Box>
        )}
        {isLoading && <LinearProgress />}

        <Paper sx={{ overflow: "hidden" }}>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(selected.length > 0 && {
                bgcolor: (theme) =>
                  alpha(
                    theme.palette.primary.main,
                    theme.palette.action.activatedOpacity
                  ),
              }),
            }}
          >
            {selected.length > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%", fontWeight: 500 }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {selected.length} Sélectionnés
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%", fontWeight: 500 }}
                id="tableTitle"
                component="div"
              >
                {total} pickups
              </Typography>
            )}
            {selected.length > 0 ? (
              <Tooltip title="Envoyer un sms">
                <IconButton onClick={() => console.log("")}>
                  <SendToMobileOutlinedIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Filter list">
                <IconButton onClick={toggleFilter}>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            )}
          </Toolbar>

          <Collapse in={open}>
            <Box sx={{ p: 2 }}></Box>
          </Collapse>

          {total > 0 && data?.length > 0 && (
            <TableContainer>
              <Table
                sx={{ width: "100%" }}
                //  size="small"
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    {checkboxTable && (
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          indeterminate={
                            selected.length > 0 &&
                            selected.length < data?.length
                          }
                          checked={
                            data?.length > 0 && selected.length === data?.length
                          }
                          onChange={handleSelectAllClick}
                          inputProps={{
                            "aria-label": "select all desserts",
                          }}
                        />
                      </StyledTableCell>
                    )}
                    <StyledTableCell align="left">Date</StyledTableCell>
                    <StyledTableCell align="left">Libelle</StyledTableCell>
                    <StyledTableCell align="left">
                      Date création
                    </StyledTableCell>
                    <StyledTableCell align="left">Nbr colis</StyledTableCell>
                    <StyledTableCell align="left">Status</StyledTableCell>

                    <StyledTableCell align="right"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(data || []).map((row, index) => {
                    const isItemSelected = selected.includes(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <StyledTableRow
                        key={index}
                        hover
                        onClick={(event) => {
                          if (checkboxTable) {
                            handleClickItem(event, row.id);
                          }
                        }}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                      >
                        {checkboxTable && (
                          <StyledTableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </StyledTableCell>
                        )}
                        <StyledTableCell component="th" scope="row">
                          {row.planned_for}
                        </StyledTableCell>
                        <StyledTableCell component="th">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell>{row.created_at}</StyledTableCell>
                        <StyledTableCell>
                          {row.shipments.length}
                        </StyledTableCell>

                        <StyledTableCell>{row.status}</StyledTableCell>

                        <StyledTableCell align="right">
                          {row.status !== PickupStatusModel.confirmed &&
                            row.status !== PickupStatusModel.picked && (
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                color="error"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setAction({
                                    item: row,
                                    action: PickupActionEnum.TO_REMOVE,
                                  });
                                }}
                              >
                                <DeleteOutlineOutlinedIcon fontSize="small" />
                              </IconButton>
                            )}

                          {row.status === PickupStatusModel.confirmed && (
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              color="warning"
                              onClick={(event) => {
                                event.stopPropagation();
                                setAction({
                                  item: row,
                                  action: PickupActionEnum.TO_CANCEL,
                                });
                              }}
                            >
                              <DoDisturbOutlinedIcon fontSize="small" />
                            </IconButton>
                          )}

                          {row.status !== PickupStatusModel.confirmed &&
                            row.status !== PickupStatusModel.picked && (
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                color="success"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setAction({
                                    item: row,
                                    action: PickupActionEnum.TO_CONFIRM,
                                  });
                                }}
                              >
                                <CheckBoxOutlinedIcon fontSize="small" />
                              </IconButton>
                            )}

                          <IconButton
                            component={Link}
                            aria-label="more"
                            id="long-button"
                            color="inherit"
                            to={`${DETAILS_PICKUP_ROUTE}/${row.id}`}
                          >
                            <ReadMoreIcon fontSize="small" />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>{" "}
            </TableContainer>
          )}
        </Paper>

        {action.item && action?.action && (
          <ConfirmDialog
            title={providerDialogParams[action?.action].getTitle(action.item)}
            body={providerDialogParams[action?.action].getBody(action.item)}
            dialogProps={{
              open: Boolean(action.item),
              onClose: () => resetAction(),
            }}
            cancelButton={{
              onClick: () => resetAction(),
            }}
            confirmButton={{
              onClick: () =>
                action?.action
                  ? providerDialogParams[action?.action].do()
                  : console.log(),
            }}
          />
        )}

        <div style={{ float: "right", padding: 20 }}>
          {total > 0 && (
            <Pagination
              count={Math.ceil(Number(total) / 10)}
              color="primary"
              showFirstButton
              showLastButton
              page={currentPage}
              onChange={handleChangePage}
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default ListPickupsPage;
