import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DepartureBoardOutlinedIcon from "@mui/icons-material/DepartureBoardOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { green } from "@mui/material/colors";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import { addHours, format } from "date-fns";
import * as React from "react";
import { Link } from "react-router-dom";
import { shipmentService } from "../../application/api/services/shipment.service";
import {
  DATE_ISO_FORMAT,
  PICKUP_ADD_HOURS,
} from "../../application/constants/appConstants";
import {
  CREATE_PACKAGE_ROUTE,
  DETAILS_PACKAGE_ROUTE,
} from "../../application/constants/routeConstants";
import { shipmentStatus } from "../../application/data/ShipmentStatus";
import { priceFormat } from "../../application/helpers/priceFormat";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { ShipmentModel } from "../../application/models/shared/ShipmentModel";
import { ShipmentStatusEnumModel } from "../../application/models/shared/shipmentStatusModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { ConfirmDialog } from "../../components/confirmDialog";
import { PageTitle } from "../../components/PageTitle";
import { SnackbarContext } from "../../components/snackbarProvider";
import { StyledTableCell } from "../../components/Styled/StyledTableCell";
import { StyledTableRow } from "../../components/Styled/StyledTableRow";
import PickupForm from "../../pages_components/pickup/forms/pickupForm";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};

interface StateModel {
  isLoading: boolean;
  error?: string;
  total: number;
  currentPage: number;
  searchText: string;
  status: ShipmentStatusEnumModel[];
  selected: number[];
  data: ShipmentModel[] | [];
  sendSmsProgress: boolean;
  toRemoveItem: ShipmentModel | undefined;
  openRemoveDialogProps: boolean;
}

const ListPreparedShipmentsPage: React.FC<PagePropsModel> = (): JSX.Element => {
  const [snackbar, setSnackbar] = React.useContext(SnackbarContext);
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);

  const [state, setState] = React.useState<StateModel>({
    isLoading: false,
    error: "",
    total: 0,
    currentPage: 1,
    data: [],
    searchText: "",
    status: [],
    selected: [],
    sendSmsProgress: false,
    toRemoveItem: undefined,
    openRemoveDialogProps: false,
  });

  const resendSms = () => {
    setOpenDrawer(true);
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };

  React.useEffect(() => {
    loadData();
  }, [state.currentPage]);

  const loadData = () => {
    setState((state) => ({
      ...state,
      isLoading: true,
      error: "",
      selected: [],
    }));
    shipmentService
      .fetchPrepared({
        pagination: { page: state.currentPage, per_page: 30 },
        status: state.status,
      })
      .then(({ data }) => {
        setState((state) => ({
          ...state,
          ...data,
          isLoading: false,
          error: "",
        }));
      })
      .catch((error) => {
        setState((state) => ({
          ...state,
          isLoading: false,
          error: String(error),
        }));
      });
  };

  const showConfirmDeleteDialog = (item: ShipmentModel) => {
    setState((state) => ({
      ...state,
      toRemoveItem: item,
      openRemoveDialogProps: true,
    }));
  };

  const hideConfirmDeleteDialog = () => {
    setState((state) => ({
      ...state,
      toRemoveItem: undefined,
      openRemoveDialogProps: false,
    }));
  };

  const remove = () => {
    shipmentService
      .remove(state.toRemoveItem?.id as number)
      .then((response) => {
        loadData();
        hideConfirmDeleteDialog();
        setSnackbar({ open: true, message: "Supprimer avec succès" });
      })
      .catch(({ response }) => {
        hideConfirmDeleteDialog();
        setSnackbar({
          open: true,
          severity: "error",
          message:
            response?.data?.message ||
            "Un problème est survenu lors de la suppression",
        });
      });
  };

  const [open, setOpen] = React.useState(false);
  const toggleFilter = () => {
    setOpen((prev) => !prev);
  };

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Liste des colis envoyés",
    },
  ];

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setState((state) => ({ ...state, currentPage: value, selected: [] }));
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = state.data.map((item) => item.id);
      setState((state) => ({ ...state, selected: newSelecteds }));
      return;
    }
    setState((state) => ({ ...state, selected: [] }));
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = state.selected.indexOf(id);

    event.stopPropagation();

    setState((state) => ({
      ...state,
      selected:
        selectedIndex > -1
          ? state.selected.filter((item) => item !== id)
          : [...state.selected, id],
    }));
  };

  const showToolbar =
    !state.isLoading &&
    Boolean(state.error) === false &&
    (state.data?.length > 0 || state.status.length > 0);

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title="List des Colis" />
        <Grid container justifyContent="space-between">
          <Grid item>
            {" "}
            <AppBreadcrumbs breadcrumbs={breadcrumbs} />
          </Grid>
          <Grid item>
            <Button
              component={Link}
              variant="contained"
              // size="small"
              to={CREATE_PACKAGE_ROUTE}
              color="primary"
              // sx={{ color: "black" }}
              startIcon={<AddIcon />}
            >
              Nouveau colis
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {Boolean(state.error) && state.isLoading === false && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="error">Error</Alert>
          </Box>
        )}
        {state.isLoading === false && state.data?.length === 0 && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="info">Aucune donnée disponible!</Alert>
          </Box>
        )}
        {state.isLoading && <LinearProgress />}

        <Paper sx={{ overflow: "hidden" }}>
          {showToolbar && (
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(state.selected.length > 0 && {
                  bgcolor: (theme) =>
                    alpha(
                      theme.palette.primary.main,
                      theme.palette.action.activatedOpacity
                    ),
                }),
              }}
            >
              {state.selected.length > 0 ? (
                <Typography
                  sx={{ flex: "1 1 100%", fontWeight: 500 }}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                >
                  {state.selected.length} Sélectionnés
                </Typography>
              ) : (
                <Typography
                  sx={{ flex: "1 1 100%", fontWeight: 500 }}
                  id="tableTitle"
                  component="div"
                >
                  {state.total} Colis
                </Typography>
              )}
              {state.selected.length > 0 ? (
                <Tooltip title="Planification">
                  <Button
                    sx={{ minWidth: 150 }}
                    variant="contained"
                    color="secondary"
                    onClick={() => resendSms()}
                    disabled={state.sendSmsProgress}
                    startIcon={
                      state.sendSmsProgress ? (
                        <CircularProgress
                          size={30}
                          sx={{
                            color: green[500],
                          }}
                        />
                      ) : (
                        <DepartureBoardOutlinedIcon />
                      )
                    }
                  >
                    Planifier
                  </Button>
                </Tooltip>
              ) : (
                <></>
              )}
            </Toolbar>
          )}

          <Collapse in={open}>
            <Box sx={{ p: 2 }}>
              <FormControl size="small" sx={{ width: 350 }}>
                <InputLabel id="demo-simple-select-label">Statut</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  multiple
                  // value={[]}
                  value={state.status}
                  input={<OutlinedInput label="Statut" />}
                  onChange={(event: SelectChangeEvent<typeof state.status>) => {
                    const {
                      target: { value },
                    } = event;

                    const _value =
                      typeof value === "string"
                        ? value
                            .split(",")
                            .map((item) => item as ShipmentStatusEnumModel)
                        : (value as ShipmentStatusEnumModel[]);

                    setState((state) => ({ ...state, status: _value }));
                  }}
                  MenuProps={MenuProps}
                >
                  {(
                    Object.keys(
                      shipmentStatus
                    ) as Array<ShipmentStatusEnumModel>
                  ).map((statusOfshipment, index) => (
                    <MenuItem key={index} value={statusOfshipment}>
                      {shipmentStatus[statusOfshipment].label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Collapse>

          {state.total > 0 && state.data?.length > 0 && (
            <TableContainer>
              <Table
                sx={{ width: "100%" }}
                //  size="small"
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        indeterminate={
                          state.selected.length > 0 &&
                          state.selected.length < state.data?.length
                        }
                        checked={
                          state.data?.length > 0 &&
                          state.selected.length === state.data?.length
                        }
                        onChange={handleSelectAllClick}
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Date d'entrée
                    </StyledTableCell>
                    {/* <StyledTableCell align="left">Expéditeur</StyledTableCell> */}
                    <StyledTableCell align="left">Destinateur</StyledTableCell>
                    <StyledTableCell align="left">Poids</StyledTableCell>
                    <StyledTableCell align="left">CRBT</StyledTableCell>
                    <StyledTableCell align="left">Code</StyledTableCell>
                    {/* <StyledTableCell align="left">Statut</StyledTableCell> */}
                    <StyledTableCell align="right"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(state.data || []).map((row, index) => {
                    const isItemSelected = state.selected.includes(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <StyledTableRow
                        key={index}
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                      >
                        <StyledTableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.received_at}
                        </StyledTableCell>
                        {/* <StyledTableCell component="th">
                          {row.sender?.first_name || "--"}{" "}
                          {row.sender?.last_name || "--"}
                        </StyledTableCell> */}
                        <StyledTableCell>
                          {row.recipient?.first_name || "--"}{" "}
                          {row.recipient.last_name || "--"}
                        </StyledTableCell>
                        <StyledTableCell>{row.weight} Kg</StyledTableCell>
                        <StyledTableCell>
                          {row.reimbursement?.enable
                            ? priceFormat(row.reimbursement.amount)
                            : "--"}{" "}
                        </StyledTableCell>
                        <StyledTableCell>{row?.identifier}</StyledTableCell>
                        {/* <StyledTableCell>
                          <ShipmentStatusChip
                            active={true}
                            status={row?.status as ShipmentStatusEnumModel}
                            size="small"
                            variant="outlined"
                          />
                        </StyledTableCell> */}
                        <StyledTableCell align="right">
                          <IconButton
                            color="error"
                            aria-label="more"
                            id="long-button"
                            onClick={(event) => {
                              event.stopPropagation();
                              showConfirmDeleteDialog(row);
                            }}
                          >
                            <DeleteOutlineOutlinedIcon fontSize="small" />
                          </IconButton>

                          <IconButton
                            component={Link}
                            aria-label="more"
                            id="long-button"
                            color="inherit"
                            to={`${DETAILS_PACKAGE_ROUTE}/${row.identifier}`}
                            // onClick={(event) => {
                            //   event.stopPropagation();
                            // }}
                          >
                            <ReadMoreIcon fontSize="small" />
                          </IconButton>

                          {/* <IconButton
                        aria-label="more"
                        id="long-button"
                        color="error"
                        onClick={() => console.log(row)}
                      >
                        <DeleteOutlineOutlinedIcon fontSize="small" />
                      </IconButton> */}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>{" "}
            </TableContainer>
          )}
        </Paper>

        {state.toRemoveItem && (
          <ConfirmDialog
            title="Suppression"
            body={`Êtes-vous sûr de vouloir supprimer ${state.toRemoveItem?.identifier}`}
            dialogProps={{
              open: state.openRemoveDialogProps,
              onClose: () => hideConfirmDeleteDialog(),
            }}
            cancelButton={{
              onClick: () => hideConfirmDeleteDialog(),
            }}
            confirmButton={{
              onClick: () => remove(),
            }}
          />
        )}

        <Drawer
          anchor={"right"}
          sx={{ marginTop: 40, maxHeight: 400 }}
          open={openDrawer}
          onClose={handleClose}
        >
          <div style={{ minWidth: 400 }}>
            <Toolbar>
              <Grid container spacing={1}>
                <Grid item>
                  <LocalShippingOutlinedIcon />
                </Grid>
                <Grid item>
                  <Typography sx={{ fontWeight: 500 }}>Pickup</Typography>
                </Grid>
              </Grid>
            </Toolbar>
            <Divider />
            <Box sx={{ padding: 4 }}>
              <Typography sx={{ mb: 2, fontWeight: 500 }}>
                {`${state.selected.length} ÉLÉMENTS SÉLECTIONNÉS `}
              </Typography>
              <PickupForm
                defaultValues={{
                  name: "",
                  planned_for: format(
                    addHours(new Date(), PICKUP_ADD_HOURS),
                    DATE_ISO_FORMAT
                  ),
                  shipments: [...state.selected],
                }}
                loading={false}
                onValide={(data) => {
                  handleClose();
                  loadData();
                }}
              />
            </Box>
          </div>
        </Drawer>

        <div style={{ float: "right", padding: 20 }}>
          {state.total > 0 && (
            <Pagination
              count={Math.ceil(Number(state.total) / 10)}
              color="primary"
              showFirstButton
              showLastButton
              page={state.currentPage}
              onChange={handleChangePage}
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default ListPreparedShipmentsPage;
