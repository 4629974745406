export const PUBLIC_ROUTE = `/public`;
export const PRIVATE_ROUTE = `/private`;

export const LIST_USERS_ROUTE = `${PRIVATE_ROUTE}/users`;
export const EDIT_USER_ROUTE = `${PRIVATE_ROUTE}/users/edit`;
export const CREATE_USER_ROUTE = `${PRIVATE_ROUTE}/users/create`;
export const PROFILE_USER_ROUTE = `${PRIVATE_ROUTE}/users/profile`;
export const CHANGE_PASSWORD_ROUTE = `${PRIVATE_ROUTE}/users/change_password`;

export const LIST_PICKUPS_ROUTE = `${PRIVATE_ROUTE}/pickups`;
export const DETAILS_PICKUP_ROUTE = `${PRIVATE_ROUTE}/pickups/details`;

export const CREATE_PACKAGE_ROUTE = `${PRIVATE_ROUTE}/colis/add`;
export const LIST_PACKAGE_ROUTE = `${PRIVATE_ROUTE}/colis`;
export const LIST_PREPARED_PACKAGE_ROUTE = `${PRIVATE_ROUTE}/colis/prepared`;
export const DETAILS_PACKAGE_ROUTE = `${PRIVATE_ROUTE}/colis/details`;

export const LIST_CUSTOMERS_ROUTE = `${PRIVATE_ROUTE}/customers`;
export const PROFILE_CUSTOMER_ROUTE = `${PRIVATE_ROUTE}/customer/profile`;

export const VALIDATION_ACCOUNT_ROUTE = `${PUBLIC_ROUTE}/account/verify`;

export const LIST_INVOICES_ROUTE = `${PRIVATE_ROUTE}/invoices`;
