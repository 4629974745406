import httpClient from "../../httpClient";
import { CustomerModel } from "../../models/shared/customerModel";
import { PaginationModel } from "../../models/shared/paginationModel";
import {
  FetchShipmentsResponseModel,
  ShipmentsFetchQueryModel,
} from "./shipment.service";

const fetch = async (search: string): Promise<CustomerModel[] | []> => {
  return httpClient.get("clients", {
    params: {
      ...(() => (search ? { q: search } : {}))(),
    },
  });
};

const fetchPagination = async (params: {
  pagination: PaginationModel;
  search?: string;
}): Promise<CustomerModel[] | []> => {
  const { pagination, search = undefined } = params;

  return httpClient.get("clients", {
    params: {
      ...pagination,
      ...(() => (search ? { search } : {}))(),
    },
  });
};

const findOne = async (id: number): Promise<CustomerModel> => {
  return httpClient.get(`clients/${id}`);
};

export type ShipmentsCustomerQueryModel = ShipmentsFetchQueryModel & {
  id: number;
};

const fetchShipments = async (
  params: ShipmentsCustomerQueryModel
): Promise<FetchShipmentsResponseModel> => {
  const {
    id,
    pagination,
    search = undefined,
    bar_code = undefined,
    status = undefined,
  } = params;
  return httpClient.get(`shipments/client/${id}`, {
    params: {
      ...pagination,
      ...(() => (status ? { status } : {}))(),
      ...(() => (bar_code ? { bar_code } : {}))(),
    },
  });
};

export const clientService = {
  fetch,
  fetchPagination,
  findOne,
  fetchShipments,
};
