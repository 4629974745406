import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { get, isUndefined } from "lodash";
import React, { FC, SyntheticEvent, useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { postOfficeService } from "../../../../application/api/services/postOffice.service";
import { PackagingTypeModel } from "../../../../application/models/shared/packagingTypeModel";
import { PostOfficeModel } from "../../../../application/models/shared/postOfficeModel";
import { PricingModel } from "../../../../application/models/shared/pricingModel";
import { PhoneInputMask } from "../../../../components/InputPhone";
import { CustomerSearchForm } from "../../../../components/customerSearchForm";

import ParcelForm from "../ParcelForm";
import { useAddShipmentsStore } from "../hooks/addShipmentsState";
import useAddress from "../../../../application/hooks/useAddress";
import { GovernorateModel } from "../../../../application/models/shared/governorateModel";
import { CityModel } from "../../../../application/models/shared/cityModel";

const ShipmentForm: FC = () => {
  const [cityOptions, setCityOptions] = useState<PostOfficeModel[] | []>([]);
  const [drawerState, setDrawertate] = React.useState<{
    open: boolean;
    iteration: number;
  }>({
    open: false,
    iteration: 0,
  });

  const handleClose = () => {
    setDrawertate({
      open: false,
      iteration: 0,
    });
  };

  const fetchPostOfficesOptions = (searchTerm: string) => {
    postOfficeService
      .fetch({ search: searchTerm, per_page: 20, page: 1 })
      .then(({ data }) => {
        setCityOptions(data.data);
      })
      .catch((error) => setCityOptions([]));
  };

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "shipments",
  });

  const watchAll = watch();

  return (
    <>
      <Grid container direction="column" spacing={3}>
        {fields.map((field, index) => (
          <Grid item key={field.id}>
            <RecipientItemForm
              index={index}
              fields={fields}
              remove={remove}
              setDrawertate={setDrawertate}
              handleClose={handleClose}
            />
            {/* <Paper sx={{ p: 3 }}>
              <Grid container spacing={0} justifyContent="space-between">
                <Grid item xs={12} md={3}>
                  <Grid container justifyContent="space-between" spacing={3}>
                    <Grid item xs={12} md={12}>
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          <Typography sx={{ fontWeight: 500 }}>
                            Destinataire {index + 1}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                            aria-label="delete"
                            color="primary"
                            size="small"
                            onClick={() =>
                              setDrawertate({
                                open: true,
                                iteration: index,
                              })
                            }
                            sx={{ float: "right", minWidth: 40 }}
                          >
                            <SearchIcon />
                          </IconButton>

                          {fields.length > 1 && (
                            <IconButton
                              aria-label="delete"
                              color="error"
                              size="small"
                              onClick={() => remove(index)}
                              sx={{ float: "right", minWidth: 40 }}
                            >
                              <CloseIcon />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <Controller
                            name={`shipments.[${index}].recipient.first_name`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                fullWidth
                                label="Nom"
                                autoComplete="new-password"
                                size="small"
                                {...field}
                                error={
                                  !isUndefined(
                                    get(
                                      errors,
                                      [
                                        "shipments",
                                        index,
                                        "recipient",
                                        "first_name",
                                      ],
                                      undefined
                                    )
                                  )
                                }
                                helperText={get(
                                  errors,
                                  [
                                    "shipments",
                                    index,
                                    "recipient",
                                    "first_name",
                                    "message",
                                  ],
                                  null
                                )}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Controller
                            name={`shipments.[${index}].recipient.last_name`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                fullWidth
                                label="Prenom"
                                autoComplete="new-password"
                                size="small"
                                {...field}
                                error={
                                  !isUndefined(
                                    get(
                                      errors,
                                      [
                                        "shipments",
                                        index,
                                        "recipient",
                                        "last_name",
                                      ],
                                      undefined
                                    )
                                  )
                                }
                                helperText={get(
                                  errors,
                                  [
                                    "shipments",
                                    index,
                                    "recipient",
                                    "last_name",
                                    "message",
                                  ],
                                  null
                                )}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Controller
                            name={`shipments.[${index}].recipient.phone_number`}
                            control={control}
                            render={({ field }) => {
                              return (
                                <TextField
                                  fullWidth
                                  label="Téléphone"
                                  autoComplete="new-password"
                                  size="small"
                                  {...field}
                                  InputLabelProps={{ shrink: true }}
                                  InputProps={{
                                    inputComponent: PhoneInputMask as any,
                                  }}
                                  error={
                                    !isUndefined(
                                      get(
                                        errors,
                                        [
                                          "shipments",
                                          index,
                                          "recipient",
                                          "phone_number",
                                        ],
                                        undefined
                                      )
                                    )
                                  }
                                  helperText={get(
                                    errors,
                                    [
                                      "shipments",
                                      index,
                                      "recipient",
                                      "phone_number",
                                      "message",
                                    ],
                                    null
                                  )}
                                />
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Controller
                            name={`shipments.[${index}].recipient.address.address_line`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                autoComplete="new-password"
                                fullWidth
                                label="Adresse"
                                size="small"
                                {...field}
                                error={
                                  !isUndefined(
                                    get(
                                      errors,
                                      [
                                        "shipments",
                                        index,
                                        "recipient",
                                        "address",
                                        "address_line",
                                      ],
                                      undefined
                                    )
                                  )
                                }
                                helperText={get(
                                  errors,
                                  [
                                    "shipments",
                                    index,
                                    "recipient",
                                    "address",
                                    "address_line",
                                    "message",
                                  ],
                                  null
                                )}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Controller
                            name={`shipments.[${index}].recipient.address.post_office`}
                            control={control}
                            render={({ field }) => {
                              return (
                                <Autocomplete
                                  noOptionsText=""
                                  size="small"
                                  fullWidth={true}
                                  popupIcon={<KeyboardArrowDownIcon />}
                                  id="role"
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                  onInputChange={(event, newInputValue) => {
                                    fetchPostOfficesOptions(newInputValue);
                                  }}
                                  getOptionLabel={(option) =>
                                    `${option.name}-${option.zip_code}`
                                  }
                                  renderOption={(props, option) => (
                                    <Box
                                      component="li"
                                      sx={{ "& > *": { mr: 2, flexShrink: 0 } }}
                                      {...props}
                                    >
                                      {`${option.name}-${option.zip_code}`}
                                    </Box>
                                  )}
                                  groupBy={(option) => option.zip_code}
                                  options={cityOptions}
                                  {...field}
                                  value={field.value || null}
                                  onChange={(
                                    event: any,
                                    newValue: PostOfficeModel | null
                                  ) => {
                                    field.onChange(newValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      label="Adresse postale"
                                      error={
                                        !isUndefined(
                                          get(
                                            errors,
                                            [
                                              "shipments",
                                              index,
                                              "recipient",
                                              "address",
                                              "post_office",
                                            ],
                                            undefined
                                          )
                                        )
                                      }
                                      helperText={get(
                                        errors,
                                        [
                                          "shipments",
                                          index,
                                          "recipient",
                                          "address",
                                          "post_office",
                                          "message",
                                        ],
                                        null
                                      )}
                                      {...params}
                                      inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password",
                                      }}
                                    />
                                  )}
                                />
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem />

                <Grid item xs={12} md={8}>
                  <ParcelForm parentIndex={index} />
                </Grid>
              </Grid>
            </Paper> */}
          </Grid>
        ))}

        <Grid item>
          <Button
            type="button"
            variant="contained"
            color="info"
            startIcon={<AddIcon />}
            sx={{ float: "left", minWidth: 100 }}
            onClick={() =>
              append({
                recipient: {
                  first_name: "",
                  last_name: "",
                  second_phone_number: "",
                  phone_number: "",
                  address: {
                    address_line: "",
                    post_office: undefined,
                  },
                },
                packages: [
                  {
                    weight: 0,
                    price: 8,
                    packaging: {
                      enable: false,
                      type: "",
                    },
                    reimbursement: {
                      enable: false,
                      amount: 0,
                      returnMode: "",
                    },
                  },
                ],
              })
            }
          >
            Destinataire{" "}
          </Button>
        </Grid>
      </Grid>

      <Drawer
        anchor={"right"}
        sx={{ marginTop: 40, maxHeight: 400 }}
        open={drawerState.open}
        onClose={handleClose}
      >
        <div style={{ minWidth: 400 }}>
          <Toolbar>
            <Grid container spacing={1}>
              <Grid item>
                <SearchIcon />
              </Grid>
              <Grid item>
                <Typography sx={{ fontWeight: 500 }}>Destinataire</Typography>
              </Grid>
            </Grid>
          </Toolbar>
          <Divider />
          <Box sx={{ padding: 4 }}>
            <CustomerSearchForm
              selectCustomer={(selected) => {
                handleClose();
                setValue(
                  `shipments.[${drawerState.iteration}].recipient`,
                  selected,
                  { shouldValidate: true }
                );
                setValue(
                  `shipments.[${drawerState.iteration}].recipient.address.post_office`,
                  selected.address.post_office,
                  { shouldValidate: true }
                );
              }}
            />
          </Box>
        </div>
      </Drawer>
    </>
  );
};

export default ShipmentForm;

const RecipientItemForm: FC<{
  index: number;
  fields: Record<"id", string>[];
  remove: (index?: number | number[] | undefined) => void;
  setDrawertate: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      iteration: number;
    }>
  >;
  handleClose: () => void;
}> = ({ index, fields, remove, setDrawertate, handleClose }) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const watchAll = watch();

  const { governorates } = useAddShipmentsStore();
  const {
    cities,
    postOffices,
    governorate,
    city,
    fetchGovernoratesIsLoading,
    fetchCitiesIsLoading,
    fetchPostOfficesIsLoading,
    handleChangeGovernorate,
    handleChangeCity,
  } = useAddress(governorates);

  return (
    <Paper sx={{ p: 3 }}>
      <Grid container spacing={0} justifyContent="space-between">
        <Grid item xs={12} md={3}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item xs={12} md={12}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography sx={{ fontWeight: 500 }}>
                    Destinataire {index + 1}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    size="small"
                    onClick={() =>
                      setDrawertate({
                        open: true,
                        iteration: index,
                      })
                    }
                    sx={{ float: "right", minWidth: 40 }}
                  >
                    <SearchIcon />
                  </IconButton>

                  {fields.length > 1 && (
                    <IconButton
                      aria-label="delete"
                      color="error"
                      size="small"
                      onClick={() => remove(index)}
                      sx={{ float: "right", minWidth: 40 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name={`shipments.[${index}].recipient.first_name`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label="Nom"
                        autoComplete="new-password"
                        size="small"
                        {...field}
                        error={
                          !isUndefined(
                            get(
                              errors,
                              ["shipments", index, "recipient", "first_name"],
                              undefined
                            )
                          )
                        }
                        helperText={get(
                          errors,
                          [
                            "shipments",
                            index,
                            "recipient",
                            "first_name",
                            "message",
                          ],
                          null
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name={`shipments.[${index}].recipient.last_name`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label="Prenom"
                        autoComplete="new-password"
                        size="small"
                        {...field}
                        error={
                          !isUndefined(
                            get(
                              errors,
                              ["shipments", index, "recipient", "last_name"],
                              undefined
                            )
                          )
                        }
                        helperText={get(
                          errors,
                          [
                            "shipments",
                            index,
                            "recipient",
                            "last_name",
                            "message",
                          ],
                          null
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name={`shipments.[${index}].recipient.phone_number`}
                    control={control}
                    render={({ field }) => {
                      return (
                        <TextField
                          fullWidth
                          label="Téléphone"
                          autoComplete="new-password"
                          size="small"
                          {...field}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            inputComponent: PhoneInputMask as any,
                          }}
                          error={
                            !isUndefined(
                              get(
                                errors,
                                [
                                  "shipments",
                                  index,
                                  "recipient",
                                  "phone_number",
                                ],
                                undefined
                              )
                            )
                          }
                          helperText={get(
                            errors,
                            [
                              "shipments",
                              index,
                              "recipient",
                              "phone_number",
                              "message",
                            ],
                            null
                          )}
                        />
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    name={`shipments.[${index}].recipient.address.address_line`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete="new-password"
                        fullWidth
                        label="Adresse"
                        size="small"
                        {...field}
                        error={
                          !isUndefined(
                            get(
                              errors,
                              [
                                "shipments",
                                index,
                                "recipient",
                                "address",
                                "address_line",
                              ],
                              undefined
                            )
                          )
                        }
                        helperText={get(
                          errors,
                          [
                            "shipments",
                            index,
                            "recipient",
                            "address",
                            "address_line",
                            "message",
                          ],
                          null
                        )}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Autocomplete
                    noOptionsText=""
                    fullWidth={true}
                    size="small"
                    popupIcon={<KeyboardArrowDownIcon />}
                    id="governorates"
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    // onInputChange={(event, newInputValue) => {
                    //   fetchPostOfficesOptions(newInputValue);
                    // }}
                    getOptionLabel={(option) => `${option.name}`}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > *": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {`${option.name}`}
                      </Box>
                    )}
                    options={governorates}
                    value={governorate || null}
                    onChange={(
                      event: SyntheticEvent<Element, Event>,
                      newValue: GovernorateModel | null
                    ) => {
                      event.preventDefault();
                      handleChangeGovernorate(newValue);
                      setValue(
                        `shipments.[${index}].recipient.address.post_office`,
                        null
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        autoComplete="new-password"
                        label="Governorat"
                        // error={Boolean(errors?.sender?.address?.post_office)}
                        // helperText={
                        //   errors?.sender?.address?.post_office
                        //     ? errors?.sender?.address?.post_office?.message
                        //     : null
                        // }
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    loading={fetchCitiesIsLoading}
                    noOptionsText=""
                    fullWidth={true}
                    size="small"
                    popupIcon={<KeyboardArrowDownIcon />}
                    id="cities"
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    // onInputChange={(event, newInputValue) => {
                    //   fetchPostOfficesOptions(newInputValue);
                    // }}
                    getOptionLabel={(option) => `${option.name}`}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > *": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {`${option.name}`}
                      </Box>
                    )}
                    options={cities}
                    value={city || null}
                    onChange={(
                      event: SyntheticEvent<Element, Event>,
                      newValue: CityModel | null
                    ) => {
                      event.preventDefault();
                      handleChangeCity(newValue);
                      setValue(
                        `shipments.[${index}].recipient.address.post_office`,
                        null
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        autoComplete="new-password"
                        label="Ville"
                        // error={Boolean(errors?.sender?.address?.post_office)}
                        // helperText={
                        //   errors?.sender?.address?.post_office
                        //     ? errors?.sender?.address?.post_office?.message
                        //     : null
                        // }
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {fetchCitiesIsLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <Controller
                    name={`shipments.[${index}].recipient.address.post_office`}
                    control={control}
                    render={({ field }) => {
                      return (
                        <Autocomplete
                          noOptionsText=""
                          size="small"
                          fullWidth={true}
                          popupIcon={<KeyboardArrowDownIcon />}
                          id="role"
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          // onInputChange={(event, newInputValue) => {
                          //   fetchPostOfficesOptions(newInputValue);
                          // }}
                          getOptionLabel={(option) =>
                            `${option.name}-${option.zip_code}`
                          }
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > *": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              {`${option.name}-${option.zip_code}`}
                            </Box>
                          )}
                          groupBy={(option) => option.zip_code}
                          options={postOffices}
                          {...field}
                          value={field.value || null}
                          onChange={(
                            event: any,
                            newValue: PostOfficeModel | null
                          ) => {
                            field.onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              label="Adresse postale"
                              error={
                                !isUndefined(
                                  get(
                                    errors,
                                    [
                                      "shipments",
                                      index,
                                      "recipient",
                                      "address",
                                      "post_office",
                                    ],
                                    undefined
                                  )
                                )
                              }
                              helperText={get(
                                errors,
                                [
                                  "shipments",
                                  index,
                                  "recipient",
                                  "address",
                                  "post_office",
                                  "message",
                                ],
                                null
                              )}
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                            />
                          )}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider orientation="vertical" flexItem />

        <Grid item xs={12} md={8}>
          <ParcelForm parentIndex={index} />
        </Grid>
      </Grid>
    </Paper>
  );
};
