import { yupResolver } from "@hookform/resolvers/yup";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Alert,
  Button,
  Grid,
  LinearProgress,
  Paper,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAuth } from "../../../application/contexts/authContex";
import { priceFormat } from "../../../application/helpers/priceFormat";
import { CustomerModel } from "../../../application/models/shared/customerModel";
import { useAddShipmentsStore } from "./hooks/addShipmentsState";
import ShipmentForm from "./RecipientForm";
import { ShipmentFormModel } from "./RecipientForm/models";
import SenderForm from "./SenderForm";
import { SenderFormModel } from "./SenderForm/models";
import { PricingDetails } from "./SenderForm/pricingDetails";
import { validationSchema } from "./ValidationSchema";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(3),
  },
}));

export interface AddShipmentsFormModel {
  sender: SenderFormModel;
  shipments: ShipmentFormModel[];
}

interface AddShipmentsFormPropsModel {
  defaultValues: AddShipmentsFormModel | undefined;
  onSubmit: (value: AddShipmentsFormModel) => void;
}

const AddShipmentsForm: FC<AddShipmentsFormPropsModel> = (
  props
): JSX.Element => {
  const auth = useAuth();

  const {
    isLoading,
    isError,
    packagingOptions,
    pricingSettings,
    getInitSettings,
    makeSender,
    intFormValues,
  } = useAddShipmentsStore();

  useEffect(() => {
    getInitSettings(
      auth.user?.customer?.pricing || [],
      makeSender(auth.user?.customer as CustomerModel)
    );
  }, []);

  if (isLoading) {
    return <LinearProgress />;
  }
  if (!isLoading && isError) {
    return <Alert severity="error">Erreur de configuration !</Alert>;
  }

  if (
    !isLoading &&
    !isError &&
    (packagingOptions === [] || pricingSettings === [])
  ) {
    return <Alert severity="error"> Configuration manquante </Alert>;
  }

  return <FormShipment {...props} />;
};

const FormShipment: FC<AddShipmentsFormPropsModel> = (props): JSX.Element => {
  const classes = useStyles();
  const {
    packagingOptions,
    pricingSettings,
    customizedPricing,
    setCustomizedPricing,
    intFormValues,
    getTotalPrice,
    maxWeight,
  } = useAddShipmentsStore();

  const formCtx = useForm<AddShipmentsFormModel>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema({ maxWeight })),
    defaultValues: props.defaultValues || intFormValues,
  });
  const formValues = formCtx.watch();

  useEffect(() => {
    if (formValues?.sender?.id !== undefined) {
      setCustomizedPricing(formValues?.sender?.pricing || undefined);
    } else {
      setCustomizedPricing(undefined);
    }
  }, [formValues?.sender?.id]);

  const onSubmit = (data: AddShipmentsFormModel) => {
    props.onSubmit(data);
  };

  const totalPrice = getTotalPrice(formValues.shipments);

  return (
    <FormProvider {...formCtx}>
      <form onSubmit={formCtx.handleSubmit(onSubmit)} autoComplete="off">
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Grid container spacing={4} alignItems="stretch">
              <Grid item xs={12} md={12}>
                <Grid container direction="column" spacing={3}>
                  <Grid item>
                    <Grid container spacing={3} justifyContent="space-between">
                      {false && (
                        <Grid item xs={12}>
                          <Grid
                            container
                            spacing={2}
                            justifyContent="space-between"
                            alignItems={"stretch"}
                          >
                            <Grid item xs={12} md={8}>
                              <Paper sx={{ p: 3, height: "100%" }}>
                                <SenderForm
                                  handleSlectSender={(sender) => {
                                    formCtx.setValue(`sender`, sender, {
                                      shouldValidate: true,
                                    });
                                    formCtx.setValue(
                                      `sender.address.post_office` as any,
                                      sender.address.post_office,
                                      { shouldValidate: true }
                                    );
                                  }}
                                  resetSenderForm={() => {
                                    formCtx.setValue(
                                      `sender`,
                                      {
                                        id: undefined,
                                        uuid: "",
                                        first_name: "",
                                        last_name: "",
                                        phone_number: "",
                                        second_phone_number: "",
                                        address: {
                                          address_line: "",
                                          post_office: undefined,
                                        },
                                        pricing: undefined,
                                      },
                                      { shouldValidate: false }
                                    );
                                  }}
                                />
                              </Paper>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Paper sx={{ p: 3, height: "100%" }}>
                                <PricingDetails />
                              </Paper>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <ShipmentForm />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Paper sx={{ p: 3 }}>
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          <Button variant="outlined">Annuler</Button>
                        </Grid>
                        <Grid item></Grid>
                        <Grid item>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                              <Typography
                                sx={{ fontWeight: 600 }}
                                color="blueviolet"
                              >
                                Total à payer : {priceFormat(totalPrice)}
                              </Typography>
                            </Grid>
                            <Grid item>
                              {" "}
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                endIcon={<KeyboardArrowRightIcon />}
                              >
                                Valider
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default AddShipmentsForm;
