import { AddShipmentsFormModel } from "../../../pages_components/shipment/forms";
import httpClient from "../../httpClient";
import { HttpResponseModel } from "../../models/shared/httpResponseModel";
import { PaginationModel } from "../../models/shared/paginationModel";
import { ReceptionResponseDataModel } from "../../models/shared/receptionResponseDataModel";
import { ShipmentModel } from "../../models/shared/ShipmentModel";
import { ShipmentStatusEnumModel } from "../../models/shared/shipmentStatusModel";

export interface FetchShipmentsResponseModel extends HttpResponseModel {
  data: { data: ShipmentModel[] | []; current_page: number; total: number };
}

export interface ReceptionResponseModel extends HttpResponseModel {
  data: ReceptionResponseDataModel;
}

export interface ShipmentsFetchQueryModel {
  pagination: PaginationModel;
  search?: string;
  bar_code?: string;
  status?: ShipmentStatusEnumModel[] | [];
}

const fetch = async (
  params: ShipmentsFetchQueryModel
): Promise<FetchShipmentsResponseModel> => {
  const {
    pagination,
    search = undefined,
    bar_code = undefined,
    status = undefined,
  } = params;
  return httpClient.get("shipments", {
    params: {
      ...pagination,
      ...(() => (status ? { status } : {}))(),
      ...(() => (bar_code ? { bar_code } : {}))(),
    },
  });
};

const fetchPrepared = async (
  params: ShipmentsFetchQueryModel
): Promise<FetchShipmentsResponseModel> => {
  const {
    pagination,
    search = undefined,
    bar_code = undefined,
    status = undefined,
  } = params;
  return httpClient.get("shipments/prepared", {
    params: {
      ...pagination,
      ...(() => (status ? { status } : {}))(),
      ...(() => (bar_code ? { bar_code } : {}))(),
    },
  });
};

const findOneShipment = async (id: string): Promise<ShipmentModel> => {
  return httpClient.get(`shipments/${id}`);
};

const reception = async (
  data: AddShipmentsFormModel
): Promise<ReceptionResponseModel> => {
  return httpClient.post("shipments", {
    ...data,
    shipments: data.shipments.map((item) => ({
      ...item,
      packages: item.packages.map((pack) => ({ ...pack, from_desktop: true })),
    })),
  });
};

const remove = async (id: number): Promise<ShipmentModel> => {
  return httpClient.delete(`shipments/${id}`);
};

export const shipmentService = {
  fetch,
  fetchPrepared,
  findOneShipment,
  reception,
  remove,
};
