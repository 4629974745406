import { sprintf } from "sprintf-js";
import create from "zustand";
import { pickupService } from "../../application/api/services/pickup.service";
import { PickupModel } from "../../application/models/shared/pickupModel";
import { ShipmentStatusEnumModel } from "../../application/models/shared/shipmentStatusModel";
import { SnackbarStateModel } from "../../components/snackbarProvider";

export enum PickupActionEnum {
  TO_REMOVE = "TO_REMOVE",
  TO_CANCEL = "TO_CANCEL",
  TO_CONFIRM = "TO_CONFIRM",
}

export enum ScopeWorking {
  FETCH_ALL = "FETCH_ALL",
  FIND_ONE = "FIND_ONE",
}

export type PickupActionProviderModel = Record<
  PickupActionEnum,
  {
    getTitle: (item: PickupModel) => string;
    getBody: (item: PickupModel) => string;
    do: () => void;
  }
>;

export interface PickupActionModel {
  action: PickupActionEnum | undefined;
  item: PickupModel | undefined;
}

export interface PickupStateModel {
  isLoading: boolean;
  error?: string;
  scopeWorking: ScopeWorking;
  total: number;
  currentPage: number;
  searchText: string;
  status: ShipmentStatusEnumModel[];
  selected: number[];
  details: PickupModel | undefined;
  data: PickupModel[];
  action: PickupActionModel;
  providerDialogParams: PickupActionProviderModel;
  snackbar: SnackbarStateModel;
  setAction: (action: PickupActionModel) => void;
  resetAction: () => void;
  handleChangePage: (event: React.ChangeEvent<unknown>, value: number) => void;
  fetchData: () => void;
  findOne: (id: number) => PickupModel;
  remove: () => void;
  cancel: () => void;
  confirm: () => void;
  handleSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickItem: (event: React.MouseEvent<unknown>, id: number) => void;
  setStatus: (value: ShipmentStatusEnumModel[]) => void;
}

export const usePickupState = create<PickupStateModel>((set, get) => ({
  isLoading: false,
  error: undefined,
  scopeWorking: ScopeWorking.FETCH_ALL,
  total: 0,
  currentPage: 1,
  searchText: "",
  status: [],
  selected: [],
  details: undefined,
  data: [],
  action: {
    action: undefined,
    item: undefined,
  },
  providerDialogParams: {
    TO_REMOVE: {
      getTitle: (item) => sprintf("Suppression", undefined),
      getBody: (item) =>
        sprintf(
          "Êtes-vous sûr de vouloir supprimer %1$s ?",
          `${item.name} : ${item.planned_for}`
        ),
      do: () => get().remove(),
    },
    TO_CANCEL: {
      getTitle: (item) => sprintf("Suppression", undefined),
      getBody: (item) =>
        sprintf(
          "Êtes-vous sûr de vouloir annuler %1$s ?",
          `${item.name} : ${item.planned_for}`
        ),
      do: () => get().cancel(),
    },
    TO_CONFIRM: {
      getTitle: (item) => sprintf("Suppression", undefined),
      getBody: (item) =>
        sprintf(
          "Êtes-vous sûr de vouloir confirmer %1$s ?",
          `${item.name} : ${item.planned_for}`
        ),
      do: () => get().confirm(),
    },
  },
  snackbar: {
    open: false,
    message: "",
  },
  setAction: (action: PickupActionModel) => {
    set((state) => ({
      ...state,
      action: {
        ...state.action,
        ...action,
      },
    }));
  },
  resetAction: () => {
    set((state) => ({
      ...state,
      action: {
        ...state.action,
        action: undefined,
        item: undefined,
      },
    }));
  },
  handleChangePage: (event: React.ChangeEvent<unknown>, value: number) => {
    set((state) => ({ ...state, currentPage: value, selected: [] }));
  },
  fetchData: () => {
    set((state) => ({
      ...state,
      isLoading: true,
      details: undefined,
      error: "",
      selected: [],
      scopeWorking: ScopeWorking.FETCH_ALL,
      action: {
        ...state.action,
        action: undefined,
        item: undefined,
      },
      snackbar: {
        open: false,
        message: "",
      },
    }));
    pickupService
      .fetch({
        pagination: { page: get().currentPage, per_page: 10 },
      })
      .then(({ data, total }) => {
        set((state) => ({
          ...state,
          data,
          total,
          isLoading: false,
          error: "",
        }));
      })
      .catch((error) => {
        set((state) => ({
          ...state,
          isLoading: false,
          error: String(error),
        }));
      });
  },
  findOne: (id: number) => {
    set((state) => ({
      ...state,
      isLoading: true,
      error: "",
      selected: [],
      scopeWorking: ScopeWorking.FIND_ONE,
      action: {
        ...state.action,
        action: undefined,
        item: undefined,
      },
      snackbar: {
        open: false,
        message: "",
      },
    }));

    pickupService
      .findOne(id)
      .then((response) => {
        set((state) => ({
          ...state,
          details: response,
          isLoading: false,
          error: "",
        }));
      })
      .catch((error) => {
        set((state) => ({
          ...state,
          isLoading: false,
          error: String(error),
        }));
      });

    return {} as PickupModel;
  },
  remove: () => {
    pickupService
      .remove(get().action.item?.id as number)
      .then((response) => {
        set((state) => ({
          ...state,
          action: {
            ...state.action,
            action: undefined,
            item: undefined,
          },
          snackbar: {
            ...state.snackbar,
            severity: "success",
            open: true,
            message: "Supprimer avec succès",
          },
        }));

        if (get().scopeWorking === ScopeWorking.FETCH_ALL) {
          get().fetchData();
        } else if (get().details?.id !== undefined) {
          get().findOne(Number(get().details?.id));
        }
      })
      .catch(({ response }) => {
        set((state) => ({
          ...state,
          action: {
            ...state.action,
            action: undefined,
            item: undefined,
          },
          snackbar: {
            ...state.snackbar,
            severity: "error",
            open: true,
            message: "Error",
          },
        }));
      });
  },
  cancel: () => {
    pickupService
      .cancel(get().action.item?.id as number)
      .then((response) => {
        set((state) => ({
          ...state,
          action: {
            ...state.action,
            action: undefined,
            item: undefined,
          },
          snackbar: {
            ...state.snackbar,
            severity: "success",
            open: true,
            message: "Opération réalisée avec succès",
          },
        }));
        if (get().scopeWorking === ScopeWorking.FETCH_ALL) {
          get().fetchData();
        } else if (get().details?.id !== undefined) {
          get().findOne(Number(get().details?.id));
        }
      })
      .catch(({ response }) => {
        set((state) => ({
          ...state,
          action: {
            ...state.action,
            action: undefined,
            item: undefined,
          },
          snackbar: {
            ...state.snackbar,
            severity: "error",
            open: true,
            message: "Error",
          },
        }));
      });
  },
  confirm: () => {
    pickupService
      .confirm(get().action.item?.id as number)
      .then((response) => {
        set((state) => ({
          ...state,
          action: {
            ...state.action,
            action: undefined,
            item: undefined,
          },
          snackbar: {
            ...state.snackbar,
            severity: "success",
            open: true,
            message: "Opération réalisée avec succès",
          },
        }));
        if (get().scopeWorking === ScopeWorking.FETCH_ALL) {
          get().fetchData();
        } else if (get().details?.id !== undefined) {
          get().findOne(Number(get().details?.id));
        }
      })
      .catch(({ response }) => {
        set((state) => ({
          ...state,
          action: {
            ...state.action,
            action: undefined,
            item: undefined,
          },
          snackbar: {
            ...state.snackbar,
            severity: "error",
            open: true,
            message: "Error",
          },
        }));
      });
  },
  handleSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = get().data.map((item) => item.id);
      set((state) => ({ ...state, selected: newSelecteds }));
      return;
    }
    set((state) => ({ ...state, selected: [] }));
  },
  handleClickItem: (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = get().selected.indexOf(id);

    event.stopPropagation();

    set((state) => ({
      ...state,
      selected:
        selectedIndex > -1
          ? state.selected.filter((item) => item !== id)
          : [...state.selected, id],
    }));
  },
  setStatus: (value: ShipmentStatusEnumModel[] | string) => {
    const _value =
      typeof value === "string"
        ? value.split(",").map((item) => item as ShipmentStatusEnumModel)
        : (value as ShipmentStatusEnumModel[]);

    set((state) => ({ ...state, status: _value }));
  },
}));
