import { InvoiceModel } from "./../../models/shared/invoiceModel";
import httpClient from "../../httpClient";
import { PaginationModel } from "../../models/shared/paginationModel";
import { HttpResponseModel } from "../../models/shared/httpResponseModel";

export interface FetchInvoicesResponseModel extends HttpResponseModel {
  data: { data: InvoiceModel[] | []; current_page: number; total: number };
}

export interface InvoicesFetchQueryModel {
  pagination: PaginationModel;
  search?: string;
  bar_code?: string;
}

const fetch = async (
  params: InvoicesFetchQueryModel
): Promise<FetchInvoicesResponseModel> => {
  return httpClient.get("invoices", {
    params: {
      ...params,
    },
  });
};

export const invoiceService = {
  fetch,
};
