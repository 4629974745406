import { Alert, Grid, LinearProgress, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { isUndefined } from "lodash";
import React, { FC, useState } from "react";
import {
  ReceptionResponseModel,
  shipmentService,
} from "../../application/api/services/shipment.service";

import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { PageTitle } from "../../components/PageTitle";
import AddShipmentsForm, {
  AddShipmentsFormModel,
} from "../../pages_components/shipment/forms";
import { Validation } from "../../pages_components/shipment/validation";
import { Voutcher } from "../../pages_components/shipment/voutcher";

enum StepModel {
  FORM = "FORM",
  VALIDATION = "VALIDATION",
}

const AddShipmentsPage: FC<PagePropsModel> = (props): JSX.Element => {
  const [state, setState] = useState<{
    formValues: AddShipmentsFormModel | undefined;
    step: StepModel;
    store: boolean;
    storedData: ReceptionResponseModel | undefined;
    isLoading: boolean;
    isError: boolean;
  }>({
    formValues: undefined,
    storedData: undefined,
    step: StepModel.FORM,
    store: false,
    isLoading: false,
    isError: false,
  });
  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Nouvel envoi",
    },
  ];

  const save = () => {
    if (!isUndefined(state.formValues)) {
      setState((state) => ({ ...state, isLoading: true, store: true }));

      shipmentService
        .reception(state.formValues)
        .then((response) => {
          setState((state) => ({
            ...state,
            isError: false,
            isLoading: false,
            storedData: response,
            store: true,
          }));
        })
        .catch((error) => {
          setState((state) => ({
            ...state,
            isError: true,
            isLoading: false,
            store: true,
          }));
        });
    }
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title="Nouvel envoi" />
        <AppBreadcrumbs breadcrumbs={breadcrumbs} />
      </Grid>

      {state.isLoading && (
        <Grid item>
          <LinearProgress color="secondary" />
        </Grid>
      )}
      {state.isError && (
        <Grid item>
          <Alert severity="error">
            Une erreur s'est produite lors de l'enregistrement
          </Alert>
        </Grid>
      )}

      <Grid item>
        {state.step === StepModel.VALIDATION &&
        state.formValues &&
        isUndefined(state.storedData) ? (
          <>
            <Validation
              isLoading={state.isLoading}
              formValue={state.formValues}
              previousButton={{
                onClick: () =>
                  setState((state) => ({ ...state, step: StepModel.FORM })),
              }}
              onValide={() => save()}
            />
          </>
        ) : !isUndefined(state.storedData) ? (
          <div>
            <Voutcher data={state.storedData} />
          </div>
        ) : (
          <AddShipmentsForm
            defaultValues={state.formValues}
            onSubmit={(formValues) =>
              setState((state) => ({
                ...state,
                formValues: formValues,
                step: StepModel.VALIDATION,
              }))
            }
          />
        )}
      </Grid>
    </Grid>
  );
};

export default AddShipmentsPage;
