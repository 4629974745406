import {
  RecordShipmentStatusModel,
  ShipmentStatusEnumModel,
} from "../models/shared/shipmentStatusModel";

export const shipmentStatus: RecordShipmentStatusModel = {
  [ShipmentStatusEnumModel.received_in_platform]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "Dans la platforme",
    activeStyle: {
      color: "#fff",
      bgColor: "#1939B7",
    },
  },
  [ShipmentStatusEnumModel.forwarded_to_platform]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "Transmis au platforme",
    activeStyle: {
      color: "#fff",
      bgColor: "#1939B7",
    },
  },
  [ShipmentStatusEnumModel.pending]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "Dans le bureau de réception",
    activeStyle: {
      color: "#fff",
      bgColor: "#1939B7",
    },
  },
  [ShipmentStatusEnumModel.received_from_customer]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "Colis ramassé",
    activeStyle: {
      color: "#fff",
      bgColor: "#102693",
    },
  },
  [ShipmentStatusEnumModel.waiting_for_forwarding]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "En attente de transfert",
    activeStyle: {
      color: "#fff",
      bgColor: "#091A7A",
    },
  },
  [ShipmentStatusEnumModel.ready_for_delivery_to_client]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "Prêt à être livré au client",
    activeStyle: {
      color: "#fff",
      bgColor: "#1E8D1C",
    },
  },
  [ShipmentStatusEnumModel.waiting_for_forwarding_to_reception_district]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "En attente de transfert vers le district de réception",
    activeStyle: {
      color: "#fff",
      bgColor: "#117117",
    },
  },
  [ShipmentStatusEnumModel.forwarded_to_reception_district]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "Transmis au district d'accueil",
    activeStyle: {
      color: "#fff",
      bgColor: "#0A5E15",
    },
  },
  [ShipmentStatusEnumModel.received_in_reception_district]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "Reçu dans le district de réception",
    activeStyle: {
      color: "#fff",
      bgColor: "#5C2626",
    },
  },
  [ShipmentStatusEnumModel.waiting_for_forwarding_to_delivery_district]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "En attente de transfert vers le district de livraison",
    activeStyle: {
      color: "#fff",
      bgColor: "#4A181C",
    },
  },
  [ShipmentStatusEnumModel.forwarded_to_delivery_district]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "Transmis au district de livraison",
    activeStyle: {
      color: "#fff",
      bgColor: "#3D0E15",
    },
  },
  [ShipmentStatusEnumModel.received_in_delivery_district]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "Reçu dans le district de livraison",
    activeStyle: {
      color: "#fff",
      bgColor: "#B77F00",
    },
  },
  [ShipmentStatusEnumModel.waiting_for_forwarding_to_delivery_office]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "En attente de transfert au bureau de livraison",
    activeStyle: {
      color: "#fff",
      bgColor: "#936300",
    },
  },
  [ShipmentStatusEnumModel.forwarded_to_delivery_office]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "Transmis au bureau de livraison",
    activeStyle: {
      color: "#fff",
      bgColor: "#7A4E00",
    },
  },
  [ShipmentStatusEnumModel.received_in_delivery_office]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "Reçu au bureau de livraison",
    activeStyle: {
      color: "#fff",
      bgColor: "#722458",
    },
  },
  [ShipmentStatusEnumModel.given_for_delivery_officer]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "Donné pour le livreur",
    activeStyle: {
      color: "#fff",
      bgColor: "#5C174C",
    },
  },
  [ShipmentStatusEnumModel.delivered_to_client]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "Livré au client",
    activeStyle: {
      color: "#fff",
      bgColor: "#4C0E43",
    },
  },
  [ShipmentStatusEnumModel.created_by_customer]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "Créé par le client",
    activeStyle: {
      color: "#fff",
      bgColor: "#4C0E43",
    },
  },
  [ShipmentStatusEnumModel.confirmed]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "Confirmé",
    activeStyle: {
      color: "#fff",
      bgColor: "#4C0E43",
    },
  },
  [ShipmentStatusEnumModel.rejected_by_officer]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "Annuller par ODD",
    activeStyle: {
      color: "#fff",
      bgColor: "#4C0E43",
    },
  },

  // [ShipmentStatusEnumModel.forwarded_to_delivery_district]: {
  //   color: "black",
  //   bgColor: "#BCBCBA",
  //   label: "En attendant",
  //   activeStyle: {
  //     color: "#fff",
  //     bgColor: "#FF5C2B",
  //   },
  // },
};
