import { Alert, CircularProgress, Container } from "@mui/material";
import { Box } from "@mui/system";
import { FC, useEffect, useState } from "react";
import { RouteProps, useHistory } from "react-router-dom";
import { authenticationService } from "../../application/api/services/authentication.service";
import { PUBLIC_ROUTE } from "../../application/constants/routeConstants";

export interface PagePropsModel extends RouteProps {
  match: {
    params: {
      token?: string;
    };
  };
}

interface StateModel {
  isLoading: boolean;
  error?: string;
}

const ValidationAccountPage: FC<PagePropsModel> = (props): JSX.Element => {
  const history = useHistory();
  const token = props?.match?.params?.token || undefined;
  const [state, setState] = useState<StateModel>({
    isLoading: true,
    error: "",
  });

  useEffect(() => {
    if (token !== undefined) {
      authenticationService
        .verify(token)
        .then((response) => {
          setState((state) => ({ ...state, isLoading: false, error: "" }));
          setTimeout(() => {
            history.push(PUBLIC_ROUTE);
          }, 2000);
        })
        .catch((error) => {
          setState((state) => ({
            ...state,
            isLoading: false,
            error: String(error),
          }));
        });
    } else {
      setState((state) => ({ ...state, isLoading: false, error: "" }));
    }
  }, [token]);

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          width: "100",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {state.isLoading && <CircularProgress />}
        {!state.isLoading && (token === undefined || state.error !== "") && (
          <img
            className="img-fluid"
            src={`${process.env.REACT_APP_PUBLIC_URL}assets/images/404.svg`}
            alt="logo"
            style={{ height: 350, width: 350 }}
          />
        )}
        {!state.isLoading && token !== undefined && state.error === "" && (
          <Alert severity="success">
            Jawek behi ya behi, la7dha barka taw narja3lek
          </Alert>
        )}
      </Box>
    </Container>
  );
};

export default ValidationAccountPage;
