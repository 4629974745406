import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export interface SnackbarStateModel {
  open: boolean;
  message: JSX.Element | string;
  severity?: "success" | "info" | "warning" | "error" | undefined;
}

type ContextValue = [SnackbarStateModel, (state: SnackbarStateModel) => void];

export const SnackbarContext = React.createContext<ContextValue>([
  {
    open: false,
    message: "",
    severity: "success",
  },
  () => console.log(""),
]);

export const SnackbarProvider: React.FC = (props) => {
  const [state, setState] = React.useState<SnackbarStateModel>({
    open: false,
    message: "",
  });
  const { open } = state;

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setState((state) => ({ ...state, open: false }));
  };

  return (
    <SnackbarContext.Provider value={[state, setState]}>
      {props?.children}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.severity}
          sx={{ width: "100%" }}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
