import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import LocalPostOfficeOutlinedIcon from "@mui/icons-material/LocalPostOfficeOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import CallSplitOutlinedIcon from "@mui/icons-material/CallSplitOutlined";
import DepartureBoardOutlinedIcon from "@mui/icons-material/DepartureBoardOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import DashboardPage from "../../pages/dashboard";
import UsersListPage from "../../pages/users";
import CreateUserPage from "../../pages/users/createUser";
import UpdateUserPage from "../../pages/users/updateUser";
import { buildAbilityFor } from "../casl/abilityConfig";
import {
  CREATE_USER_ROUTE,
  EDIT_USER_ROUTE,
  LIST_CUSTOMERS_ROUTE,
  LIST_INVOICES_ROUTE,
  LIST_PACKAGE_ROUTE,
  LIST_PICKUPS_ROUTE,
  LIST_PREPARED_PACKAGE_ROUTE,
  LIST_USERS_ROUTE,
  PRIVATE_ROUTE,
} from "../constants/routeConstants";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import { MenuModel } from "../models/shared/menuModel";
import { UserModel } from "./../models/shared/userModel";
import ListPreparedShipmentsPage from "../../pages/shipment/prepared";
import ListPickupsPage from "../../pages/pickup";
import InvoicesPage from "../../pages/invoices";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

export const communMenuItems: MenuModel[] = [
  {
    label: "Tableau de Bord",
    href: PRIVATE_ROUTE,
    page: () => DashboardPage,
    visibility: true,
    icon: () => DashboardOutlinedIcon,
    image: "/assets/icons/dashboard.svg",
    ability: (user: UserModel): boolean => {
      return true;
    },
  },
  {
    label: "Les colis",
    href: LIST_PACKAGE_ROUTE,
    page: () => DashboardPage,
    visibility: true,
    icon: () => LocalPostOfficeOutlinedIcon,
    image: "/assets/icons/dashboard.svg",
    ability: (user: UserModel): boolean => {
      return true;
    },
    subMenuItems: [
      {
        label: "Suivi colis",
        href: LIST_PACKAGE_ROUTE,
        page: () => DashboardPage,
        visibility: true,
        icon: () => CallSplitOutlinedIcon,
        image: "/assets/icons/dashboard.svg",
        ability: (user: UserModel): boolean => {
          return true;
        },
      },
      {
        label: "Préparation pickup",
        href: LIST_PREPARED_PACKAGE_ROUTE,
        page: () => ListPreparedShipmentsPage,
        visibility: true,
        icon: () => DepartureBoardOutlinedIcon,
        image: "/assets/icons/dashboard.svg",
        ability: (user: UserModel): boolean => {
          return true;
        },
      },
      {
        label: "Les pickups",
        href: LIST_PICKUPS_ROUTE,
        page: () => ListPickupsPage,
        visibility: true,
        icon: () => AssignmentTurnedInOutlinedIcon,
        image: "/assets/icons/dashboard.svg",
        ability: (user: UserModel): boolean => {
          return true;
        },
      },
    ],
  },
  {
    label: "Les clients",
    href: LIST_CUSTOMERS_ROUTE,
    page: () => DashboardPage,
    visibility: true,
    icon: () => GroupOutlinedIcon,
    image: "/assets/icons/dashboard.svg",
    ability: (user: UserModel): boolean => {
      return true;
    },
  },

  {
    label: "Les Factures",
    href: LIST_INVOICES_ROUTE,
    page: () => InvoicesPage,
    visibility: true,
    icon: () => DescriptionOutlinedIcon,
    image: "/assets/icons/Invoice-amico.svg",
    ability: (user: UserModel): boolean => {
      // return user?.office?.is_platform === false;
      return true;
    },
  },

  // {
  //   label: "Management",
  //   icon: () => SettingsIcon,
  //   visibility: true,
  //   ability: (user: UserModel): boolean => {
  //     const ability = buildAbilityFor(user);
  //     return ability.can("read", "ACSL_MenuModel");
  //   },
  //   subMenuItems: [
  //     {
  //       label: "Gestion des utilisateurs",
  //       href: LIST_USERS_ROUTE,
  //       page: () => UsersListPage,
  //       icon: () => PeopleAltOutlinedIcon,
  //       visibility: true,
  //       image: "/assets/icons/Personal files-cuate.svg",
  //       ability: (user: UserModel): boolean => {
  //         return true;
  //       },
  //     },
  //     {
  //       label: "Nouveau utilisateur",
  //       href: CREATE_USER_ROUTE,
  //       page: () => CreateUserPage,
  //       icon: () => PeopleAltOutlinedIcon,
  //       visibility: false,
  //       image: "/assets/icons/Personal files-cuate.svg",
  //       ability: (user: UserModel): boolean => {
  //         return true;
  //       },
  //     },
  //     {
  //       label: "Modification utilisateur",
  //       href: `${EDIT_USER_ROUTE}/:id?`,
  //       page: () => UpdateUserPage,
  //       icon: () => PeopleAltOutlinedIcon,
  //       visibility: false,
  //       image: "/assets/icons/Personal files-cuate.svg",
  //       ability: (user: UserModel): boolean => {
  //         return true;
  //       },
  //     },
  //   ],
  // },
];
