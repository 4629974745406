import httpClient from "../../httpClient";
import { CityModel } from "../../models/shared/cityModel";
import { GovernorateModel } from "../../models/shared/governorateModel";

interface PaginationResponseModel {
  data: GovernorateModel[] | [];
  total: number;
  current_page: number;
}

const fetch = async (params: {
  search?: string;
  per_page?: number;
  page?: number;
}): Promise<PaginationResponseModel> => {
  const { search = undefined, per_page = undefined, page = undefined } = params;
  return httpClient
    .get("governorates", {
      params: {
        ...(() => (page ? { page } : {}))(),
        ...(() => (per_page ? { per_page } : {}))(),
        ...(() => (search ? { search } : {}))(),
      },
    })
    .then(({ data }) => data);
};

const fetchCitiesOfGovernorate = async (
  governorateId: string
): Promise<CityModel[] | []> => {
  return httpClient
    .get(`governorates/${governorateId}/cities`)
    .then(({ data: { data } }) => data);
};

export const governorateService = {
  fetch,
  fetchCitiesOfGovernorate,
};
