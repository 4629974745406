import { useQuery, UseQueryResult } from "react-query";
import {
  FetchInvoicesResponseModel,
  invoiceService,
  InvoicesFetchQueryModel,
} from "../services/invoice.service";

export const useFetchInvoicesQuery = (
  params: InvoicesFetchQueryModel
): UseQueryResult<FetchInvoicesResponseModel> => {
  return useQuery<FetchInvoicesResponseModel>({
    queryKey: ["fetchInvoices-query", JSON.stringify(params)].join("-"),
    queryFn: () => invoiceService.fetch(params),
    // enabled: !isUndefined(data),
    // refetchInterval: 3000,
  });
};
