import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

interface CardControlerPropsModel {
  mainTitle: string;
  secondaryTitle: string;
  imgSrc: string;
  value: number;
}

const CardControler: React.FC<CardControlerPropsModel> = ({
  mainTitle,
  secondaryTitle,
  imgSrc,
  value,
}): JSX.Element => {
  const theme = useTheme();

  return (
    <Card sx={{ display: "flex", p: 2, justifyContent: "space-between" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography style={{ marginBottom: 20, fontWeight: 500 }}>
            {mainTitle}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            {secondaryTitle}
          </Typography>
        </CardContent>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography style={{ fontSize: 24, fontWeight: 600 }}>
            {value}
          </Typography>
        </Box>
      </Box>
      <CardMedia
        component="img"
        sx={{ maxWidth: 151 }}
        image={imgSrc}
        alt="Live from space album cover"
      />
    </Card>
  );
};

export default CardControler;
