import { Grid } from "@mui/material";
import React, { FC } from "react";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { PageTitle } from "../../components/PageTitle";
import CardControler from "../../pages_components/dashboard/CardControler";
import { SimpleBarChart } from "../../pages_components/dashboard/SimpleBarChart";
import SimpleLineChart from "../../pages_components/dashboard/SimpleLineChart";
import { SimplePieChart } from "../../pages_components/dashboard/SimplePieChart";

const DashboardPage: FC<PagePropsModel> = (props): JSX.Element => {
  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Tableau de bord",
      href: "/",
    },
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title="Tableau de bord" />
        <Grid container justifyContent="space-between">
          <Grid item>
            {" "}
            <AppBreadcrumbs breadcrumbs={breadcrumbs} />
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item md={4} xs={12}>
            <CardControler
              mainTitle="Total Active Users"
              secondaryTitle="+2.6%"
              imgSrc="/assets/images/undraw_logistics_x4dc.png"
              value={18.765}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <CardControler
              mainTitle="Total Active Users"
              secondaryTitle="+2.6%"
              imgSrc="/assets/images/undraw_Online_calendar_re_wk3t.png"
              value={18.765}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <CardControler
              mainTitle="Total Active Users"
              secondaryTitle="+2.6%"
              imgSrc="/assets/images/undraw_Empty_re_opql.png"
              value={18.765}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item md={5} xs={12}>
            <SimpleLineChart />
          </Grid>
          <Grid item md={7} xs={12}>
            <SimpleBarChart />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item md={5} xs={12}>
            <SimplePieChart />
          </Grid>
          <Grid item md={7} xs={12}>
            <SimpleBarChart />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DashboardPage;
