import Logout from "@mui/icons-material/Logout";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import { Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { authenticationService } from "../../../api/services/authentication.service";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../../../constants/authConstants";
import {
  CHANGE_PASSWORD_ROUTE,
  PROFILE_USER_ROUTE,
} from "../../../constants/routeConstants";
import { useAuth } from "../../../contexts/authContex";
import { removeStorageItem } from "../../../helpers/localStorage";

export const AccountMenu: React.FC = () => {
  const auth = useAuth();

  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    authenticationService.logout().then(() => {
      removeStorageItem(ACCESS_TOKEN);
      removeStorageItem(REFRESH_TOKEN);
      auth.signout(() => {
        history.push("/public");
      });
    });
  };

  return (
    <React.Fragment>
      <Tooltip title="Account settings">
        <Button
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          startIcon={<PersonOutlinedIcon />}
        >
          {auth.user?.customer?.first_name} {auth.user?.customer?.last_name}
        </Button>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: "auto",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => history.push(PROFILE_USER_ROUTE)}>
          <ListItemIcon>
            <PersonOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Profile
        </MenuItem>

        <MenuItem onClick={() => history.push(CHANGE_PASSWORD_ROUTE)}>
          <ListItemIcon>
            <VpnKeyOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Changement de mot de passe
        </MenuItem>

        <Divider />

        <MenuItem onClick={() => logout()}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};
