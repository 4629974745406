import React, { FC } from "react";
import { Route, RouteProps, Switch, useRouteMatch } from "react-router-dom";
import DashboardPage from "../../pages/dashboard";
import ListShipmentsPage from "../../pages/shipment";
import ShipmentDetailsPage from "../../pages/shipment/details";
import ChangePasswordPage from "../../pages/users/changePassword";
import CreateUserPage from "../../pages/users/createUser";
import UsersListPage from "../../pages/users/index";
import UserProfilePage from "../../pages/users/profile";
import UpdateUserPage from "../../pages/users/updateUser";
import ListClientsPage from "../../pages/client";
import {
  CHANGE_PASSWORD_ROUTE,
  CREATE_PACKAGE_ROUTE,
  CREATE_USER_ROUTE,
  DETAILS_PACKAGE_ROUTE,
  DETAILS_PICKUP_ROUTE,
  EDIT_USER_ROUTE,
  LIST_CUSTOMERS_ROUTE,
  LIST_INVOICES_ROUTE,
  LIST_PACKAGE_ROUTE,
  LIST_PICKUPS_ROUTE,
  LIST_PREPARED_PACKAGE_ROUTE,
  LIST_USERS_ROUTE,
  PROFILE_CUSTOMER_ROUTE,
  PROFILE_USER_ROUTE,
} from "../constants/routeConstants";
import { communMenuItems } from "../data/menuItems";
import { PrivateWorkSpaceLayout } from "../layouts/privateWorkSpaceLayout";
import { MenuModel } from "../models/shared/menuModel";
import ClientProfilePage from "../../pages/client/profile";
import AddShipmentsPage from "../../pages/shipment/addShipments";
import ListPreparedShipmentsPage from "../../pages/shipment/prepared";
import ListPickupsPage from "../../pages/pickup";
import PickupDetailsPage from "../../pages/pickup/details";
import InvoicesPage from "../../pages/invoices";

const PrivateLayoutRoute: FC<RouteProps> = (props) => {
  const { path } = useRouteMatch();

  const menuList = (communMenuItems as Array<MenuModel>).reduce((acc, cur) => {
    const currentList = cur?.subMenuItems || [];
    return [...acc, ...currentList];
  }, [] as MenuModel[]);

  return (
    <PrivateWorkSpaceLayout>
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={(props) => <DashboardPage {...props} />}
        />

        {/*************** Shipment routes ***************/}

        <Route
          exact
          path={`${CREATE_PACKAGE_ROUTE}`}
          render={(props) => <AddShipmentsPage {...props} />}
        />

        <Route
          exact
          path={`${DETAILS_PACKAGE_ROUTE}/:id?`}
          render={(props) => <ShipmentDetailsPage {...props} />}
        />

        <Route
          exact
          path={`${LIST_PREPARED_PACKAGE_ROUTE}`}
          render={(props) => <ListPreparedShipmentsPage {...props} />}
        />

        <Route
          exact
          path={`${LIST_PACKAGE_ROUTE}`}
          render={(props) => <ListShipmentsPage {...props} />}
        />

        {/*************** Pickup routes ***************/}

        <Route
          exact
          path={`${DETAILS_PICKUP_ROUTE}/:id?`}
          render={(props) => <PickupDetailsPage {...props} />}
        />

        <Route
          exact
          path={`${LIST_PICKUPS_ROUTE}`}
          render={(props) => <ListPickupsPage {...props} />}
        />

        {/*************** Client routes ***************/}

        <Route
          path={`${PROFILE_CUSTOMER_ROUTE}/:id?`}
          render={(props) => <ClientProfilePage {...props} />}
        />

        <Route
          path={`${LIST_CUSTOMERS_ROUTE}`}
          render={(props) => <ListClientsPage {...props} />}
        />

        {/*************** Invoices routes ***************/}
        <Route
          path={`${LIST_INVOICES_ROUTE}`}
          render={(props) => <InvoicesPage {...props} />}
        />

        {/*************** User routes ***************/}

        <Route
          path={`${EDIT_USER_ROUTE}/:id?`}
          render={(props) => <UpdateUserPage {...props} />}
        />
        <Route
          path={`${PROFILE_USER_ROUTE}`}
          render={(props) => <UserProfilePage {...props} />}
        />
        <Route
          path={`${CREATE_USER_ROUTE}`}
          render={(props) => <CreateUserPage {...props} />}
        />
        <Route
          path={`${CHANGE_PASSWORD_ROUTE}`}
          render={(props) => <ChangePasswordPage {...props} />}
        />
        <Route
          path={`${LIST_USERS_ROUTE}`}
          render={(props) => <UsersListPage {...props} />}
        />
      </Switch>
    </PrivateWorkSpaceLayout>
  );
};

export default PrivateLayoutRoute;
