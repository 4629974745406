import { UserModel } from "./../models/shared/userModel";
import {
  AbilityBuilder,
  Ability,
  AbilityClass,
  SubjectRawRule,
  ExtractSubjectType,
  MongoQuery,
} from "@casl/ability";
import { AnyObject } from "@casl/ability/dist/types/types";
import { ACSL_InvoiceModel } from "../models/shared/invoiceModel";
import { ACSL_MenuModel } from "../models/shared/menuModel";

type Actions = "manage" | "create" | "read" | "update" | "delete";
type Subjects =
  | "ACSL_InvoiceModel"
  | ACSL_InvoiceModel
  | "ACSL_MenuModel"
  | ACSL_MenuModel
  | "all";

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

export default function defineRulesFor(
  user: UserModel
): SubjectRawRule<
  Actions,
  ExtractSubjectType<Subjects>,
  MongoQuery<AnyObject>
>[] {
  const { can, rules } = new AbilityBuilder(AppAbility);

  can("manage", "all");

  // if (user.customer.first_name === "Codbox") {
  //   can("manage", "all");
  //   can(["read"], "ACSL_MenuModel", { label: "Management" });
  // } else {
  //   can(["read", "create", "update", "delete"], "ACSL_InvoiceModel");
  //   // can(["update", "delete"], "ACSL_InvoiceModel", { id: 1 });
  // }

  return rules;
}

export function buildAbilityFor(user: UserModel): AppAbility {
  return new AppAbility(defineRulesFor(user), {
    detectSubjectType: (object) => object?.type,
  });
}
