import { yupResolver } from "@hookform/resolvers/yup";
import { DateTimePicker, StaticDateTimePicker } from "@mui/lab";
import DatePicker from "@mui/lab/DatePicker";
import { Alert, Box, Button, Grid, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import {
  addHours,
  format,
  formatISO,
  isAfter,
  isDate,
  parse,
  parseISO,
} from "date-fns";
import React, { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { pickupService } from "../../../application/api/services/pickup.service";
import {
  DATE_FORMAT,
  DATE_ISO_FORMAT,
  PICKUP_ADD_HOURS,
} from "../../../application/constants/appConstants";
import { PickupFormModel } from "./models";

interface PickupFormState {
  isLoading: boolean;
  error?: string;
}

const PickupForm: FC<{
  defaultValues?: PickupFormModel;
  loading?: boolean;
  onValide: (data: PickupFormModel) => void;
}> = (props) => {
  const [state, setState] = useState<PickupFormState>({
    isLoading: false,
    error: "",
  });

  const schema = yup.object().shape({
    name: yup.string().required(),
    planned_for: yup
      .string()
      .required()
      .test(
        "is-valide",
        "La date et l'heure doit etre après 2 heurs",
        (value) => {
          return isAfter(
            parse(String(value), DATE_ISO_FORMAT, new Date()),
            addHours(new Date(), PICKUP_ADD_HOURS)
          );
        }
      ),
    shipments: yup.array().required(),
  });

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<PickupFormModel>({
    resolver: yupResolver(schema),
    defaultValues: props?.defaultValues || {
      name: "",
      planned_for: format(new Date(), DATE_ISO_FORMAT),
      shipments: [],
    },
  });

  const onSubmit = (data: PickupFormModel) => {
    setState((state) => ({ ...state, isLoading: true, error: "" }));
    pickupService
      .create({
        ...data,
        planned_for: format(
          parse(data.planned_for, DATE_ISO_FORMAT, new Date()),
          DATE_FORMAT
        ),
      })
      .then((response) => {
        setState((state) => ({ ...state, isLoading: false, error: "" }));
        props.onValide(data);
      })
      .catch((error) => {
        setState((state) => ({
          ...state,
          isLoading: false,
          error: String(error),
        }));
      });
  };

  const formValues = watch();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" spacing={3}>
        {Boolean(state.error) && (
          <Grid item>
            <Alert severity="error">{state.error}</Alert>
          </Grid>
        )}
        <Grid item>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    autoComplete="new-password"
                    fullWidth
                    label="Nom"
                    {...field}
                    error={Boolean(errors?.name)}
                    helperText={errors?.name ? errors?.name.message : null}
                  />
                )}
              />
            </Grid>
            {/* <Grid item>
              <Controller
                name="planned_for"
                control={control}
                render={({ field }) => (
                  <DateTimePicker
                    readOnly
                    ampm={false}
                    label="Date"
                    {...field}
                    inputFormat={DATE_FORMAT}
                    minDate={new Date()}
                    minTime={addHours(new Date(), 2)}
                    value={parse(field.value, DATE_FORMAT, new Date())}
                    onChange={(newValue) => {
                      field.onChange(format(newValue as Date, DATE_FORMAT));
                    }}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                )}
              />
            </Grid> */}
            <Grid item>
              <Controller
                name="planned_for"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    id="datetime-local"
                    label="Date"
                    type="datetime-local"
                    {...field}
                    value={field.value}
                    inputProps={{
                      min: format(new Date(), DATE_ISO_FORMAT),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.planned_for)}
                    helperText={
                      errors?.planned_for ? errors?.planned_for.message : null
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item></Grid>
            <Grid item>
              <Box sx={{ mt: 1, position: "relative" }}>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={state.isLoading}
                >
                  Sauvegarder
                </Button>
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default PickupForm;
