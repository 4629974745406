import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Alert,
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { get } from "lodash";
import { FC, useState } from "react";
import { useFetchInvoicesQuery } from "../../application/api/hooks/invoices.hook";

import { FetchInvoicesResponseModel } from "../../application/api/services/invoice.service";
import { priceFormat } from "../../application/helpers/priceFormat";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { PageTitle } from "../../components/PageTitle";
import { Pagination } from "../../components/pagination";
import { StyledTableCell } from "../../components/Styled/StyledTableCell";
import { StyledTableRow } from "../../components/Styled/StyledTableRow";

interface StateModel {
  currentPage: number;
  searchText: string;
}

const InvoicesPage: FC<PagePropsModel> = (): JSX.Element => {
  const [state, setState] = useState<StateModel>({
    currentPage: 1,
    searchText: "",
  });

  const fetchQuery = useFetchInvoicesQuery({
    pagination: { page: state.currentPage, per_page: 10 },
  });

  const {
    data: { data, current_page, total },
  } = get(fetchQuery, ["data"], {
    data: [],
    current_page: 1,
    total: 0,
  }) as FetchInvoicesResponseModel;

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setState((state) => ({ ...state, currentPage: value }));
  };

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Liste des factures",
    },
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title="List des Colis" />
        <Grid container justifyContent="space-between">
          <Grid item>
            {" "}
            <AppBreadcrumbs breadcrumbs={breadcrumbs} />
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Box pb={1}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography>
                <b>{total}</b> Factures
              </Typography>
            </Grid>
            <Grid item></Grid>
          </Grid>
        </Box>{" "}
        {fetchQuery.isError === true && fetchQuery.isLoading === false && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="error">Error</Alert>
          </Box>
        )}
        {fetchQuery.isLoading === false && data?.length === 0 && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="info">Aucune donnée disponible!</Alert>
          </Box>
        )}
        {fetchQuery.isLoading && <LinearProgress />}
        {total > 0 && data?.length > 0 && (
          <TableContainer component={Paper}>
            <Table
              sx={{ width: "100%" }}
              size="small"
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Date</StyledTableCell>
                  <StyledTableCell align="left">Client</StyledTableCell>
                  <StyledTableCell align="left">Contact</StyledTableCell>
                  <StyledTableCell align="left">Total TTC</StyledTableCell>
                  <StyledTableCell align="left">Statut</StyledTableCell>
                  <StyledTableCell align="right">Facture</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(data || []).map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {format(new Date(row.created_at), "dd-MM-yyyy HH:mm:ss")}
                    </StyledTableCell>
                    <StyledTableCell component="th">
                      {row.customer_name}
                    </StyledTableCell>
                    <StyledTableCell>{row.customer_phone}</StyledTableCell>
                    <StyledTableCell>
                      {priceFormat(Number(row?.total))}
                    </StyledTableCell>
                    <StyledTableCell>{row?.status}</StyledTableCell>
                    <StyledTableCell align="right">
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        color="inherit"
                        href={`${row.file_url}`}
                        target="_blank"
                      >
                        <FileDownloadIcon fontSize="small" />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>{" "}
          </TableContainer>
        )}
        <div style={{ float: "right", padding: 20 }}>
          {total > 0 && (
            <Pagination
              count={Math.ceil(Number(total) / 10)}
              color="primary"
              showFirstButton
              showLastButton
              page={state.currentPage}
              onChange={handleChangePage}
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default InvoicesPage;
