export enum ShipmentStatusEnumModel {
  pending = "pending",
  received_from_customer = "received_from_customer",
  waiting_for_forwarding = "waiting_for_forwarding",
  ready_for_delivery_to_client = "ready_for_delivery_to_client",
  waiting_for_forwarding_to_reception_district = "waiting_for_forwarding_to_reception_district",
  forwarded_to_reception_district = "forwarded_to_reception_district",
  received_in_reception_district = "received_in_reception_district",
  waiting_for_forwarding_to_delivery_district = "waiting_for_forwarding_to_delivery_district",
  forwarded_to_delivery_district = "forwarded_to_delivery_district",
  received_in_delivery_district = "received_in_delivery_district",
  waiting_for_forwarding_to_delivery_office = "waiting_for_forwarding_to_delivery_office",
  forwarded_to_delivery_office = "forwarded_to_delivery_office",
  received_in_delivery_office = "received_in_delivery_office",
  given_for_delivery_officer = "given_for_delivery_officer",
  delivered_to_client = "delivered_to_client",
  forwarded_to_platform = "forwarded_to_platform",
  received_in_platform = "received_in_platform",
  created_by_customer = "created_by_customer",
  confirmed = "confirmed",
  rejected_by_officer = "rejected_by_officer",
}

export interface ShipmentStatusModel {
  color: string;
  bgColor: string;
  label: string;
  activeStyle: {
    color: string;
    bgColor: string;
  };
}

export type RecordShipmentStatusModel = Record<
  ShipmentStatusEnumModel,
  ShipmentStatusModel
>;
