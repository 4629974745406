import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { Box } from "@mui/system";
import { get } from "lodash";
import { FC, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useFindOneShipmentQuery } from "../../application/api/hooks/shipment.hooks";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { ShipmentModel } from "../../application/models/shared/ShipmentModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { PageTitle } from "../../components/PageTitle";
import { ShipmentAttempts } from "../../pages_components/shipment/attempts";
import { ShipmentBarCodeCard } from "../../pages_components/shipment/barCodeContainer";
import { ShipmentHistory } from "../../pages_components/shipment/history";
import { DeliveryAndCrbtCode } from "./deliveryAndCrbtCode";

const ShipmentDetailsPage: FC<PagePropsModel> = (props): JSX.Element => {
  const id = props?.match?.params?.id || undefined;

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const oneShipmentQuery = useFindOneShipmentQuery(String(id));
  const shipmentData = get(
    oneShipmentQuery,
    ["data", "data"],
    undefined
  ) as ShipmentModel;

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Detail colis ",
    },
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title="Detail Colis" />

        <Grid container justifyContent="space-between">
          <Grid item>
            {" "}
            <AppBreadcrumbs breadcrumbs={breadcrumbs} />
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={12} md={8}>
            <Paper>
              <Stack direction={"column"} spacing={2}>
                <Box sx={{ height: 30, ml: 3, mt: 3 }}>
                  <Typography sx={{ fontWeight: 900 }}> Historique </Typography>
                </Box>
                <ShipmentHistory data={shipmentData?.history || []} />
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ width: "100%" }}>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                    <ConfirmationNumberOutlinedIcon />
                  </Avatar>
                }
                action={
                  <IconButton aria-label="settings" onClick={handlePrint}>
                    <PrintOutlinedIcon />
                  </IconButton>
                }
                title="Ticket"
                subheader={shipmentData?.received_at}
              />

              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                {shipmentData && (
                  <div ref={componentRef}>
                    <ShipmentBarCodeCard shipment={shipmentData} />
                  </div>
                )}

                <Box>
                  <Divider flexItem />
                </Box>
                <DeliveryAndCrbtCode shipmentData={shipmentData} />
              </CardContent>
            </Card>
          </Grid>
          {shipmentData?.attempts !== undefined &&
            shipmentData?.attempts?.length > 0 && (
              <Grid item xs={12} md={8}>
                <Stack direction={"column"} spacing={2} sx={{ mt: 4 }}>
                  <Box sx={{ height: 30 }}>
                    <Typography sx={{ fontWeight: 900, fontSize: 16 }}>
                      {" "}
                      Tentatives des livraisons{" "}
                    </Typography>
                  </Box>
                  <ShipmentAttempts data={shipmentData?.attempts || []} />
                </Stack>
              </Grid>
            )}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ShipmentDetailsPage;
