import { GovernorateModel } from "./../../../../application/models/shared/governorateModel";
import { AddShipmentsFormModel } from "./../index";
import { PackagingTypeModel } from "../../../../application/models/shared/packagingTypeModel";
import { PricingModel } from "../../../../application/models/shared/pricingModel";
import create from "zustand";
import { addShipmentsIntValuesForm } from "./addShipmentsIntValues";
import { ShipmentFormModel } from "../RecipientForm/models";
import { ParcelModel } from "../ParcelForm/models";
import { SenderFormModel } from "../SenderForm/models";
import { CustomerModel } from "../../../../application/models/shared/customerModel";
import { governorateService } from "../../../../application/api/services/governorate.service";

export interface AddShipmentsStateModel {
  governorates: GovernorateModel[];
  intFormValues: AddShipmentsFormModel;
  isLoading: boolean;
  isError: boolean;
  maxWeight: number;
  packagingOptions: PackagingTypeModel[] | [];
  pricingSettings: PricingModel[] | [];
  customizedPricing: PricingModel[] | undefined;
  makeSender: (customer: CustomerModel) => SenderFormModel;
  setCustomizedPricing: (pricing: PricingModel[] | undefined) => void;
  getInitSettings: (
    pricing: PricingModel[] | [],
    sender: SenderFormModel
  ) => void;
  getTotalPrice: (shipments: AddShipmentsFormModel["shipments"]) => number;
  reducerShipment: (
    previousValue: number,
    currentValue: ShipmentFormModel
  ) => number;
  reducerPackages: (previousValue: number, currentValue: ParcelModel) => number;
  getPricing: () => PricingModel[];
  getParcelPrice: (parcelValues: ParcelModel) => Number;
}

export const useAddShipmentsStore = create<AddShipmentsStateModel>(
  (set, get) => ({
    governorates: [],
    intFormValues: addShipmentsIntValuesForm,
    isLoading: true,
    isError: false,
    maxWeight: 30,
    packagingOptions: [],
    pricingSettings: [],
    customizedPricing: undefined,
    getInitSettings: (
      pricing: PricingModel[] | [],
      sender: SenderFormModel
    ) => {
      governorateService
        .fetch({ search: undefined, per_page: 24, page: 1 })
        .then((response) => {
          set((state) => ({
            ...state,
            isLoading: false,
            isError: false,
            packagingOptions: [],
            pricingSettings: pricing,
            intFormValues: { ...state.intFormValues, sender },
            governorates: response?.data || [],
          }));
        })
        .catch((error) => {
          set((state) => ({
            ...state,
            isLoading: false,
            isError: true,
            governorates: [],
          }));
        });

      // set((state) => ({
      //   ...state,
      //   isLoading: false,
      //   isError: false,
      //   packagingOptions: [],
      //   pricingSettings: pricing,
      //   intFormValues: { ...state.intFormValues, sender },
      // }));
    },
    makeSender: (customer: CustomerModel): SenderFormModel => {
      const { id, first_name, last_name, phone_number, address } = customer;
      return {
        id,
        first_name,
        last_name,
        phone_number,
        address,
      };
    },
    setCustomizedPricing: (pricing: PricingModel[] | undefined) => {
      set((state) => ({
        ...state,
        customizedPricing:
          pricing && Array.isArray(pricing) && pricing.length > 0
            ? pricing
            : undefined,
        maxWeight:
          pricing && Array.isArray(pricing) && pricing.length > 0
            ? Math.max(...pricing.map((item) => item.max))
            : Math.max(...get().pricingSettings.map((item) => item.max)),
      }));
    },
    getTotalPrice: (shipments: ShipmentFormModel[]) => {
      return shipments.reduce(get().reducerShipment, 0);
    },
    reducerShipment: (
      previousValue: number,
      currentValue: ShipmentFormModel
    ) => {
      const { packages } = currentValue;
      const totalPerPackages = packages.reduce(get().reducerPackages, 0);
      return Number(previousValue) + Number(totalPerPackages);
    },
    reducerPackages: (
      previousValue: number,
      currentValue: ParcelModel
    ): number => {
      return Number(previousValue) + Number(currentValue.price);
    },
    getPricing: () => {
      return get().customizedPricing || get().pricingSettings;
    },
    getParcelPrice: (parcelValues: ParcelModel) => {
      const weightPrice =
        get()
          .getPricing()
          ?.find(
            (item) =>
              item.min < Number(parcelValues.weight) &&
              item.max >= Number(parcelValues.weight)
          )?.price || 0;

      const packagingTypePrice =
        parcelValues.packaging.enable &&
        parcelValues.packaging.type !== undefined
          ? parcelValues.packaging.type.price
          : 0;

      return Number(weightPrice) + Number(packagingTypePrice);
    },
  })
);
