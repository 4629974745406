import { isUndefined } from "lodash";
import { useQuery, UseQueryResult } from "react-query";
import { ShipmentModel } from "../../models/shared/ShipmentModel";
import {
  FetchShipmentsResponseModel,
  shipmentService,
  ShipmentsFetchQueryModel,
} from "../services/shipment.service";

export const useFetchShipmentsQuery = (
  params: ShipmentsFetchQueryModel
): UseQueryResult<FetchShipmentsResponseModel> => {
  return useQuery<FetchShipmentsResponseModel>({
    queryKey: ["fetchShipments-query", JSON.stringify(params)].join("-"),
    queryFn: () => shipmentService.fetch(params),
  });
};

export const useFindOneShipmentQuery = (
  id: string
): UseQueryResult<ShipmentModel> => {
  return useQuery<ShipmentModel>({
    queryKey: ["findOne-query", id].join("-"),
    queryFn: () => {
      return !isUndefined(id)
        ? shipmentService.findOneShipment(id)
        : Promise.reject();
    },
    enabled: !isUndefined(id),
  });
};

export const useFetchPreparedShipmentsQuery = (
  params: ShipmentsFetchQueryModel
): UseQueryResult<FetchShipmentsResponseModel> => {
  return useQuery<FetchShipmentsResponseModel>({
    queryKey: ["fetchShipments-query", JSON.stringify(params)].join("-"),
    queryFn: () => shipmentService.fetchPrepared(params),
  });
};
