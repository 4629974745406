import { useQuery } from "react-query";
import { UseQueryResult } from "react-query";
import { isUndefined } from "lodash";
import { CustomerModel } from "../../models/shared/customerModel";
import {
  clientService,
  ShipmentsCustomerQueryModel,
} from "../services/client.service";
import { FetchShipmentsResponseModel } from "../services/shipment.service";

export const useFetchClientsQuery = (
  search: string
): UseQueryResult<CustomerModel[] | []> => {
  return useQuery<CustomerModel[] | []>({
    queryKey: ["fetchCustomer-query", search].join("-"),
    queryFn: () => {
      return !isUndefined(search)
        ? clientService.fetch(search)
        : Promise.reject();
    },
    enabled: !isUndefined(search) && search.length > 2,
  });
};

export const useFindOneClientQuery = (
  id: number
): UseQueryResult<CustomerModel> => {
  return useQuery<CustomerModel>({
    queryKey: ["findOne-query", id].join("-"),
    queryFn: () => {
      return !isUndefined(id) ? clientService.findOne(id) : Promise.reject();
    },
    enabled: !isUndefined(id) && !isNaN(id),
  });
};

export const useFetchClientShipmentsQuery = (
  params: ShipmentsCustomerQueryModel
): UseQueryResult<FetchShipmentsResponseModel> => {
  return useQuery<FetchShipmentsResponseModel>({
    queryKey: ["fetchShipments-query", JSON.stringify(params)].join("-"),
    queryFn: () => clientService.fetchShipments(params),
    // enabled: !isUndefined(data),
    // refetchInterval: 3000,
  });
};
